import React from 'react'
import { computed, observable, runInAction, action } from 'mobx'
import { observer } from 'mobx-react'
import { Button, Form, Modal, Icon, Message, Header, Table, Checkbox } from 'semantic-ui-react'
import { IconButton } from 'spider/semantic-ui/Button'
import { isFeatureFlagEnabled } from 'helpers/featureFlags'
import { TargetTextInput, TargetSelect, TargetTextArea } from 'spider/semantic-ui/Target'
import { IntegrationStore, TYPE_ICONS, TYPE_COLORS } from 'store/Integration';
import ColliTable from 'container/Perform/Step/Carrier/ColliTable'
import { showSaveNotification, showNotification } from 'helpers/notification'
import { print, getPrinters, getPrinterType } from 'helpers/print'
import RightDivider from 'spider/component/RightDivider'
import PerformStep from './Step'
import { StyledToolbar, LAST_USED_KEY } from './helpers'
import { upsServices } from '../../../store/UpsIntegration'
import { humanReadable } from 'helpers/decimal';
import styled from 'styled-components'
import CarrierRequestRowTable from './Carrier/CarrierRequestRowTable'
import { CarrierRequest } from '../../../store/CarrierRequest'

const TabsContainer = styled.div`
    display:inline-block;
    height: 100%;
    width:33%;
`
const ShipmentInput = styled.div`
    margin-left: 8px;
    background: #FFFFFF;
    border-radius: 12px;
    width: 100%;
    height: 98.8%;
    padding: 20px;
    overflow: auto;
`
const ShipmentInputContainer = styled.div`
    display: inline-block;
    width: 66%;
    height: 100%;
    position: absolute;
    top: 0%;
`

const CarrierStepContainer = styled.div`
    background-color: '#F5F5F5';
    margin: 20px;
    height: 100%;
    position: relative;
`
const Tab = styled.div`
    height:5%;
    background-color: ${({ active }) => active ? '#FFFFFF' : '#F5F5F5'};
    color: ${({ active }) => active ? 'rgba(0, 0, 0, 0.87);' : 'rgba(0, 0, 0, 0.38)'}; 
    border-radius: 8px 8px 0px 0px;
    display: inline-block;
    padding: 8px 24px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    cursor: pointer;
`

const TabContentContainer = styled.div`
    width: 100%;
    height: 95%;
    background: #FFFFFF;
    border-radius: 0 12px 12px 12px;
    padding: 20px;
    overflow: auto;
`

const ISO3166_reverse = {
    'Andorra': 'AD', 'United Arab Emirates': 'AE', 'Afghanistan': 'AF', 'Antigua & Barbuda': 'AG', 'Anguilla': 'AI', 'Albania': 'AL', 'Armenia': 'AM', 'Netherlands Antilles': 'AN',
    'Angola': 'AO', 'Antarctica': 'AQ', 'Argentina': 'AR', 'American Samoa': 'AS', 'Austria': 'AT', 'Australia': 'AU', 'Aruba': 'AW', 'Azerbaijan': 'AZ', 'Bosnia and Herzegovina': 'BA',
    'Barbados': 'BB', 'Bangladesh': 'BD', 'Belgium': 'BE', 'Burkina Faso': 'BF', 'Bulgaria': 'BG', 'Bahrain': 'BH', 'Burundi': 'BI', 'Benin': 'BJ', 'Bermuda': 'BM', 'Brunei Darussalam': 'BN',
    'Bolivia': 'BO', 'Brazil': 'BR', 'Bahama': 'BS', 'Bhutan': 'BT', 'Burma (no longer exists)': 'BU', 'Bouvet Island': 'BV', 'Botswana': 'BW', 'Belarus': 'BY', 'Belize': 'BZ', 'Canada': 'CA',
    'Cocos (Keeling) Islands': 'CC', 'Central African Republic': 'CF', 'Congo': 'CG', 'Switzerland': 'CH', "Côte D'ivoire (Ivory Coast)": 'CI', 'Cook Iislands': 'CK', 'Chile': 'CL', 'Cameroon': 'CM',
    'China': 'CN', 'Colombia': 'CO', 'Costa Rica': 'CR', 'Czechoslovakia (no longer exists)': 'CS', 'Cuba': 'CU', 'Cape Verde': 'CV', 'Christmas Island': 'CX', 'Cyprus': 'CY', 'Czech Republic': 'CZ',
    'German Democratic Republic (no longer exists)': 'DD', 'Germany': 'DE', 'Djibouti': 'DJ', 'Denmark': 'DK', 'Dominica': 'DM', 'Dominican Republic': 'DO', 'Algeria': 'DZ', 'Ecuador': 'EC',
    'Estonia': 'EE', 'Egypt': 'EG', 'Western Sahara': 'EH', 'Eritrea': 'ER', 'Spain': 'ES', 'Ethiopia': 'ET', 'Finland': 'FI', 'Fiji': 'FJ', 'Falkland Islands (Malvinas)': 'FK', 'Micronesia': 'FM',
    'Faroe Islands': 'FO', 'France': 'FR', 'France, Metropolitan': 'FX', 'Gabon': 'GA', 'United Kingdom (Great Britain)': 'GB', 'Grenada': 'GD', 'Georgia': 'GE', 'French Guiana': 'GF', 'Ghana': 'GH',
    'Gibraltar': 'GI', 'Greenland': 'GL', 'Gambia': 'GM', 'Guinea': 'GN', 'Guadeloupe': 'GP', 'Equatorial Guinea': 'GQ', 'Greece': 'GR', 'South Georgia and the South Sandwich Islands': 'GS',
    'Guatemala': 'GT', 'Guam': 'GU', 'Guinea-Bissau': 'GW', 'Guyana': 'GY', 'Hong Kong': 'HK', 'Heard & McDonald Islands': 'HM', 'Honduras': 'HN', 'Croatia': 'HR', 'Haiti': 'HT', 'Hungary': 'HU',
    'Indonesia': 'ID', 'Ireland': 'IE', 'Israel': 'IL', 'India': 'IN', 'British Indian Ocean Territory': 'IO', 'Iraq': 'IQ', 'Islamic Republic of Iran': 'IR', 'Iceland': 'IS', 'Italy': 'IT',
    'Jamaica': 'JM', 'Jordan': 'JO', 'Japan': 'JP', 'Kenya': 'KE', 'Kyrgyzstan': 'KG', 'Cambodia': 'KH', 'Kiribati': 'KI', 'Comoros': 'KM', 'St. Kitts and Nevis': 'KN', "Korea, Democratic People's Republic of": 'KP',
    'Korea, Republic of': 'KR', 'Kuwait': 'KW', 'Cayman Islands': 'KY', 'Kazakhstan': 'KZ', "Lao People's Democratic Republic": 'LA', 'Lebanon': 'LB', 'Saint Lucia': 'LC', 'Liechtenstein': 'LI', 'Sri Lanka': 'LK',
    'Liberia': 'LR', 'Lesotho': 'LS', 'Lithuania': 'LT', 'Luxembourg': 'LU', 'Latvia': 'LV', 'Libyan Arab Jamahiriya': 'LY', 'Morocco': 'MA', 'Monaco': 'MC', 'Moldova, Republic of': 'MD', 'Madagascar': 'MG',
    'Marshall Islands': 'MH', 'Mali': 'ML', 'Mongolia': 'MN', 'Myanmar': 'MM', 'Macau': 'MO', 'Northern Mariana Islands': 'MP', 'Martinique': 'MQ', 'Mauritania': 'MR', 'Monserrat': 'MS', 'Malta': 'MT', 'Mauritius': 'MU',
    'Maldives': 'MV', 'Malawi': 'MW', 'Mexico': 'MX', 'Malaysia': 'MY', 'Mozambique': 'MZ', 'Namibia': 'NA', 'New Caledonia': 'NC', 'Niger': 'NE', 'Norfolk Island': 'NF', 'Nigeria': 'NG', 'Nicaragua': 'NI',
    'Netherlands': 'NL', 'Norway': 'NO', 'Nepal': 'NP', 'Nauru': 'NR', 'Neutral Zone (no longer exists)': 'NT', 'Niue': 'NU', 'New Zealand': 'NZ', 'Oman': 'OM', 'Panama': 'PA', 'Peru': 'PE', 'French Polynesia': 'PF',
    'Papua New Guinea': 'PG', 'Philippines': 'PH', 'Pakistan': 'PK', 'Poland': 'PL', 'St. Pierre & Miquelon': 'PM', 'Pitcairn': 'PN', 'Puerto Rico': 'PR', 'Portugal': 'PT', 'Palau': 'PW', 'Paraguay': 'PY', 'Qatar': 'QA',
    'Réunion': 'RE', 'Romania': 'RO', 'Russian Federation': 'RU', 'Rwanda': 'RW', 'Saudi Arabia': 'SA', 'Solomon Islands': 'SB', 'Seychelles': 'SC', 'Sudan': 'SD', 'Sweden': 'SE', 'Singapore': 'SG', 'St. Helena': 'SH',
    'Slovenia': 'SI', 'Svalbard & Jan Mayen Islands': 'SJ', 'Slovakia': 'SK', 'Sierra Leone': 'SL', 'San Marino': 'SM', 'Senegal': 'SN', 'Somalia': 'SO', 'Suriname': 'SR', 'Sao Tome & Principe': 'ST',
    'Union of Soviet Socialist Republics (no longer exists)': 'SU', 'El Salvador': 'SV', 'Syrian Arab Republic': 'SY', 'Swaziland': 'SZ', 'Turks & Caicos Islands': 'TC', 'Chad': 'TD', 'French Southern Territories': 'TF',
    'Togo': 'TG', 'Thailand': 'TH', 'Tajikistan': 'TJ', 'Tokelau': 'TK', 'Turkmenistan': 'TM', 'Tunisia': 'TN', 'Tonga': 'TO', 'East Timor': 'TP', 'Turkey': 'TR', 'Trinidad & Tobago': 'TT', 'Tuvalu': 'TV',
    'Taiwan, Province of China': 'TW', 'Tanzania, United Republic of': 'TZ', 'Ukraine': 'UA', 'Uganda': 'UG', 'United States Minor Outlying Islands': 'UM', 'United States of America': 'US', 'Uruguay': 'UY',
    'Uzbekistan': 'UZ', 'Vatican City State (Holy See)': 'VA', 'St. Vincent & the Grenadines': 'VC', 'Venezuela': 'VE', 'British Virgin Islands': 'VG', 'United States Virgin Islands': 'VI', 'Viet Nam': 'VN', 'Vanuatu': 'VU',
    'Wallis & Futuna Islands': 'WF', 'Samoa': 'WS', 'Democratic Yemen (no longer exists)': 'YD', 'Yemen': 'YE', 'Mayotte': 'YT', 'Yugoslavia': 'YU', 'South Africa': 'ZA', 'Zambia': 'ZM', 'Zaire': 'ZR', 'Zimbabwe': 'ZW',
    'Unknown or unspecified country': 'ZZ',
};

@observer
export default class PerformCarrierStep extends PerformStep {
    static maxNumberofShippingmethods = 5;

    //A boolean variable representing whether or not the content of the carrierStep is accessible
    @observable isAccessible = false;
    @observable selectedCarrier = null;
    @observable numberOfColliTables = 1;
    @observable extraServices = null;
    @observable selectedExtraServices = null;
    @observable printData = null
    @observable printed = false
    @observable printing = false
    @observable selectedPrinter = null
    @observable availablePrinters = null
    @observable hasGottenCarrierRequestResponse = false
    @observable hasSendCarrierRequest = false
    @observable response = null
    @observable recipientEmail = null
    @observable recipientPhone = null
    @observable recipientCountry = null
    @observable showConfirmModal = false;
    @observable selectedService = '11';
    @observable activeTab = 'shipmentDetails'
    @observable carrierRequestRows = null
    @observable carrierRequest = null
    @observable pickOrderLines = null
    @observable selectedPickLines = new Set();
    //All carrier integrations used (in order to have a selector of what carrier to use)
    @observable carrierIntegrations = new IntegrationStore({
        relations: [
            'shippingUnits',
            'janKredietIntegration',
            'transmissionIntegration',
            'upsIntegration',
        ]
    });
    //The sales order corresponding to the productionRequest
    @observable salesOrder;


    constructor(...args) {
        super(...args)

        this.onPrint = this.onPrint.bind(this)
        this.searchPrinters = this.searchPrinters.bind(this)
    }

    componentDidMount() {
        const { productionRequest } = this.props
        super.componentDidMount()

        this.data.carrierData = Array(PerformCarrierStep.maxNumberofShippingmethods);
        // We retrieve all the carrierIntegrations for the carrier_step
        this.carrierIntegrations.fetch().then(() => {
            // filter out non carrier integrations:
            this.carrierIntegrations.models = this.carrierIntegrations.models.filter((integration) => {
                return integration.integrationType === 'CARRIER';
            });
            this.isAccessible = isFeatureFlagEnabled('carrier_integration') && this.carrierIntegrations.length > 0;
            this.selectedCarrier = this.isAccessible ? this.carrierIntegrations.models[0].id : null;
            this.data.carrierId = this.selectedCarrier;
            this.data.deliveryAddress = null
            this.extraServices = this.getExtraServices()
        });

        // We retrieve the salesorder linked to this productionRequest
        this.salesOrder = productionRequest.outShipment.salesOrder;
        this.outShipment = productionRequest.outShipment
        this.pickOrderLines = productionRequest.outShipment.lines
        this.data.salesOrderId = this.salesOrder.id;
        this.data.selectedExtraServices = [];
        this.data.selected_service = '11';
        this.data.recipientEmail = null;
        this.data.recipientPhone = null;
        this.data.recipientCountry = this.getCountryCodeFromDelivery();

        // Start looking for printers:
        this.searchPrinters()

        // Check if we can prefill customer data (email + phone)
        console.log('sales_order', this.salesOrder)
        if (this.salesOrder.customer.email) {
            this.data.recipientEmail = this.salesOrder.customer.email;
            this.recipientEmail = this.salesOrder.customer.email;
        }
        if (this.salesOrder.customer.phone) {
            let phoneNumber = this.salesOrder.customer.phone.toString()
            if (this.salesOrder.customer.phoneExtension) {
                phoneNumber = this.salesOrder.customer.phoneExtension.toString() + phoneNumber;
            }
            this.data.recipientPhone = phoneNumber
            this.recipientPhone = phoneNumber
            
        }
    }

    async onSubmit(quantity) {
        const { onPerform } = this.props

        this.errors = []
        try {
            return await onPerform({ ...this.data, quantity }, this.startedAt)
        } catch (err) {
            if (err.response && err.response.status === 400 && err.response.data.code === 'ValidationError') {
                const errors = err.response.data.errors
                runInAction(() => {
                    this.errors = errors
                    this.hasGottenCarrierRequestResponse = false;
                    this.hasSendCarrierRequest = false
                })
            }
            throw err
        }
    }

    // Eventually we can get the country code from exact, but right now we get it out of the sales order(which is a pain)
    getCountryCodeFromDelivery() {
        if (this.salesOrder == null) {
            return ''
        }
        let deliveryAddres = this.salesOrder.deliveryAddress.match(/[^\r\n]+/g);
        let country = deliveryAddres[3].trim();
        country = country.split(' ')
        if (country.length === 1) {
            country = country[0];
        }
        else if (country.length > 1) {
            country = country[1];
        }
        if (country in ISO3166_reverse) {
            country = ISO3166_reverse[country];
        }
        else {
            country = ''
        }
        return country
    }


    async searchPrinters() {
        this.availablePrinters = null
        let printerType = 'zebra'
        let defaultPrinter = null

        const availablePrinters = (await getPrinters()).filter((printer) => getPrinterType(printer) === printerType)

        runInAction(() => {
            this.availablePrinters = availablePrinters

            const lastUsedPrinter = localStorage.getItem(LAST_USED_KEY)
            if (availablePrinters.length === 1) {
                this.selectedPrinter = availablePrinters[0]
            } else if (availablePrinters.includes(defaultPrinter)) {
                this.selectedPrinter = defaultPrinter
            } else if (availablePrinters.includes(lastUsedPrinter)) {
                this.selectedPrinter = lastUsedPrinter
            }
        })
    }

    displayDimensionsOfRow(index) {
        const curDimensions = this.response['dimensions'][index]
        return 'L:' + curDimensions['length'] + ' W:' + curDimensions['width'] + ' H:' + curDimensions['height'];
    }

    async onPrint(quantity = 1, skipPrintOnPaper = false) {
        const { step } = this.props
        this.printing = true

        try {
            // Get print data to a uniform format.
            // Same is for the split step, we need to print the shipping labels
            let printSettings = {
                copies: 1,
                type: 'label',
                labelPrinter: "zebra",
                labelTemplate: this.shippingLabeltemplate ? this.shippingLabeltemplate : null,
            }
            // Actual print
            const performPrint = async (printData, i) => {
                const _copies = this.copies !== printSettings.copies ? this.copies : printSettings.copies
                await print(this.selectedPrinter, printData.instructions, { copies: _copies, key: i })
            }

            const printPromises = [];
            if (this.printData === null) {
                const allPrintData = [];
                try {
                    const shippingLabelTemplate = this.response['instructions']
                    for (let i = 1; i <= this.numberOfCollo; i++) {
                        if (typeof this.response['instructions'] === "string") {
                            this.response['instructions'] = shippingLabelTemplate;
                            this.response['instructions'] = this.response['instructions'].replace("{{carrier_collonumber}}", i);
                            this.response['instructions'] = this.response['instructions'].replace("{{carrier_barcode}}", this.shipmentBarcodes[i]);
                            this.response['instructions'] = this.response['instructions'].replace("{{carrier_collodimensions}}", this.displayDimensionsOfRow(i));
                        }
                        else {
                            this.response['instructions'] = shippingLabelTemplate[i]
                        }

                        allPrintData.push(this.response);
                        if (!skipPrintOnPaper) {
                            printPromises.push(performPrint(this.response, 1));
                        }
                    }

                } catch (error) {
                    if (allPrintData.length === 0) {
                        throw error;
                    }
                }
                this.printData = allPrintData;
            } else if (!skipPrintOnPaper) {
                printPromises.push(...this.printData.map(performPrint));
            }

            await Promise.all(printPromises);

            this.printed = true
            showNotification(skipPrintOnPaper
                ? t('workStation.production.performModal.printStep.printSkipped')
                : t('workStation.production.performModal.printStep.printedSuccesfully')
            )
        } catch (err) {
            console.log('printing error', err)
            window.viewStore.showNotification({
                key: step.id,
                error: true,
                dismissAfter: 4000,
                message: `${err}`,
                icon: 'print',
            })
        } finally {
            this.printing = false
        }
    }

    @computed get carrierSelectionOptions() {
        let integrationOptions = this.carrierIntegrations.models.map((carrierIntegration) => ({
            value: carrierIntegration.id,
            text: (<>
                <Icon name={TYPE_ICONS[carrierIntegration.type]} style={{ color: TYPE_COLORS[carrierIntegration.type] }} />
                {carrierIntegration.name}
            </>
            ),
            icon: true,
            labelPosition: 'left',
        }))

        integrationOptions.push({
            value: -1,
            text: 'inHouse'
        });
        return integrationOptions
    }


    getNumberOfColliTables() {
        return this.numberOfColliTables
    }

    @observable carrierRequestID = null

    submitCarrierRequest = async () => {
        this.hasGottenCarrierRequestResponse = false
        this.hasSendCarrierRequest = true;
        const res = await this.onSubmit()
        if (res) {
            this.response = res;
            this.carrierRequestID = this.response['carrier_request'] 
            this.fetchCarrierRequest(this.carrierRequestID)
        }
    }

    fetchCarrierRequest(carrierRequestID){
        this.carrierRequest = new CarrierRequest(
            { 
                id: carrierRequestID
            },
            {   
                relations:[
                    'carrierRequestRows.carrierRequestRowsPickOrderLinks.pickOrderLine.salesOrderLine.articleType',
                    'carrierRequestRows.carrierRequestRowsPickOrderLinks.pickOrderLine.productionRequest',
                    'carrierRequestRows.carrierRequestRowsPickOrderLinks.pickOrderLine.carrierRequestRowsPickOrderLinks',
                ]
            });
        this.carrierRequest.fetch().then(()=>{
            this.carrierRequestRows = this.carrierRequest.carrierRequestRows;
            this.hasGottenCarrierRequestResponse = true;
            showSaveNotification();
        });
    }

    calculateCollowOfset(index) {
        let offset = 0;
        for (let j = 0; j < index; j++) {
            if (this.data.carrierData[j] != null) {
                offset += this.data.carrierData[j]['numberOfColli']
            }
        }
        return offset
    }

    async getExtraServices() {
        const { step } = this.props
        let result = await step.carrierStep.extraServiceOptions({ 'carrierId': this.data.carrierId, 'salesOrderId': this.salesOrder.id })
        this.extraServices = result
    }

    @computed get getExtraServicesOptions() {
        if (this.extraServices == null) {
            return []
        }
        let extraServiceOptions = Object.keys(this.extraServices).map((key) => ({
            value: key,
            text: this.extraServices[key]
        }));


        return extraServiceOptions
    }

    renderCarrierForm() {
        if (!this.isAccessible) {
            return
        }

        return (
            <>
                <Header as='h1'>
                    Shipment input
                </Header>
                <Form>
                    <Modal
                        data-test-confirm-modal-carrier-step
                        closeIcon
                        open={this.showConfirmModal}
                        onClose={() => {
                            this.showConfirmModal = false;
                        }}
                        header={(<><Icon style={{ color: '#EBBB12', marginRight: '0.5em' }} name={'warning sign'} /> <div>test</div> </>)}
                    >
                        <Modal.Header>
                            <Icon style={{ color: '#EBBB12', marginRight: '0.5em' }} name={'warning sign'} />
                            {t('workStation.production.performModal.carrierStep.confirm.header')}
                        </Modal.Header>
                        <Modal.Content>
                            {t('workStation.production.performModal.carrierStep.confirm.content')}
                        </Modal.Content>
                        <Modal.Actions>
                            <RightDivider />
                            <Button
                                data-test-warning-modal-cancel-button
                                icon="remove"
                                labelPosition="left"
                                content={t('workStation.production.performModal.carrierStep.closeButton')}
                                onClick={() => this.showConfirmModal = false}
                            />
                            <Button
                                data-test-warning-modal-confirm-button
                                icon="check"
                                labelPosition="left"
                                content={t('workStation.production.performModal.carrierStep.confirmButton')}
                                onClick={() => {
                                    this.showConfirmModal = false;
                                    this.submitCarrierRequest();
                                }}
                            />
                        </Modal.Actions>
                    </Modal>
                    {this.selectedCarrier !== null && [...Array(this.getNumberOfColliTables())].map((x, i) => {
                        return (
                            <ColliTable
                                colliTableId={i}
                                onChange={(newData) => {
                                    this.data.carrierData[i] = newData;
                                }}
                                isFrozenField={() => {
                                    return this.hasSendCarrierRequest || this.hasGottenCarrierRequestResponse
                                }
                                }
                                shippingLabelCodes={this.hasGottenCarrierRequestResponse && this.response ? this.response['barcodes'] : null}
                                colloStartNr={this.calculateCollowOfset(i)}
                                shippingUnits={this.carrierIntegrations.get(this.selectedCarrier)?.shippingUnits ?? []}
                                selectedPickLines={this.selectedPickLines}
                                emptyPickLines={()=>{
                                    this.selectedPickLines = new Set();
                                }}
                                pickLinesStore={this.pickOrderLines}
                            />
                        )
                    })}
                </Form>
            </>
        )
    }

    renderCarrierStepPrint() {
        let printersOptions
        if (this.availablePrinters === null) {
            printersOptions = []
        } else if (this.availablePrinters.length === 0) {
            printersOptions = [{ value: null, text: <i>{t('workStation.production.performModal.printStep.noPrintersAvailable')}</i> }]
        } else {
            printersOptions = this.availablePrinters.map((printer) => ({ value: printer, text: printer }))
        }

        return (
            <>
                <Button
                    primary
                    data-test-print-button
                    icon="print"
                    labelPosition="left"
                    content={t('workStation.production.performModal.carrierStep.printButton')}
                    loading={this.printing}
                    disabled={this.selectedPrinter === null || !this.hasGottenCarrierRequestResponse}
                    onClick={() => this.onCarrierPrint()}
                />
                {this.availablePrinters === null || this.availablePrinters.length === 0 ? (
                    <IconButton name="refresh" onClick={this.searchPrinters} />
                ) :
                    <TargetSelect noLabel data-test-printers-selector
                        value={this.selectedPrinter}
                        placeholder={t('workStation.production.performModal.printStep.selectPrinter')}
                        options={printersOptions}
                        onChange={(printer) => this.selectedPrinter = printer}
                        loading={this.availablePrinters === null}
                    />
                }
            </>
        )
    }

    onCarrierPrint() {
        this.numberOfCollo = this.response['collo_numbers'].length;
        if (typeof this.response['instructions'] === "string") {
            this.response['instructions'] = this.response['instructions'].replace('{{carrier_order}}', this.response['carrier_order'])
        }
        this.shipmentBarcodes = this.response['barcodes']
        this.onPrint(this.numberOfCollo, false);
    }

    renderButtons() {
        const { step, productionRequest, maxPerformCount } = this.props
        const showGoToMain = ['buy', 'sell', 'transfer_line'].includes(productionRequest.processVersion.batchType.type)
        const showGoToNextLinesButton = step.nextStep.type === 'subprocesses' && ['pick_order', 'receive_order', 'transfer'].includes(productionRequest.processVersion.batchType.type)

        return (
            <StyledToolbar>
                {/* Stats are not shown at the moment until we figure out a good place 
                    where to put them if the screen is too small. */}
                {/* {this.renderStats()} */}
                <RightDivider />
                {this.errors && this.errors.length > 0 && (
                    <Message>{this.errors[0].message}</Message>
                )}
                <RightDivider />
                <>
                    <Button data-test-send-order
                        icon='save'
                        content={t('workStation.production.performModal.carrierStep.send_request.label')}
                        labelPosition="left"
                        onClick={() => {
                            if (this.selectedCarrier < 0 && this.data.carrierData[0] == null) {
                                this.showConfirmModal = true;
                                return;
                            }
                            try {
                                this.submitCarrierRequest()
                            } catch (errors) {
                                this.errors = errors
                                this.hasGottenCarrierRequestResponse = false;
                            }
                        }}
                        disabled={this.hasSendCarrierRequest}
                        loading={this.hasSendCarrierRequest && !this.hasGottenCarrierRequestResponse}
                    />
                    {this.isAccessible && this.renderCarrierStepPrint()}
                    <RightDivider />
                </>
                <Button primary data-test-confirm-button
                    icon="check"
                    labelPosition="left"
                    content={t('workStation.production.performModal.confirmButton', { count: humanReadable(maxPerformCount) })}
                    onClick={() => this.onConfirm(true, false)}
                    disabled={!this.printed}
                />
                {(showGoToNextLinesButton || showGoToMain) && (
                    <Button primary data-test-go-to-main-production-request
                        icon="arrow right"
                        labelPosition="left"
                        content={t('workStation.production.performModal.goToMain')}
                        onClick={() => this.onConfirm(true, true)}
                        disabled={!this.printed}
                    />
                )}
            </StyledToolbar>
        )
    }

    renderShipmentDetails(){
        const selectedCarrier = this.carrierIntegrations.get(this.selectedCarrier) 
        const isTransmission = selectedCarrier?.type === 'transmission';
        const isUps = selectedCarrier?.type === 'ups';

        return (
            <Form>
                <Form.Group widths="equal">
                    <TargetSelect
                        data-test-carrier-selector
                        label={t('carrierStep.carrier')}
                        target={this.selectedCarrier}
                        value={this.selectedCarrier}
                        store={this.carrierIntegrations}
                        options={this.carrierSelectionOptions}
                        onChange={(value) => {
                            this.selectedExtraServices = []
                            this.selectedCarrier = value;
                            this.data.carrierId = this.selectedCarrier;
                            this.extraServices = this.getExtraServices()
                        }}
                        disabled={this.carrierSelectionOptions.length <= 1 || this.hasSendCarrierRequest || this.hasGottenCarrierRequestResponse}
                    />
                </Form.Group>
                <Form.Group widths="equal">
                    {isTransmission && (
                        <TargetSelect
                            data-test-extra-carrier-options
                            label={t('workStation.production.performModal.carrierStep.extraServices')}
                            disabled={this.extraServices == null || this.extraServices.length === 0 || this.hasSendCarrierRequest || this.hasGottenCarrierRequestResponse}
                            options={this.getExtraServicesOptions}
                            multiple={true}
                            onChange={(value) => {
                                this.selectedExtraServices = value;
                                this.data.selectedExtraServices = value;
                            }}
                        />
                    )}
                    {isUps && (
                        <TargetSelect
                            data-test-ups-services-select
                            search
                            label={t('workStation.production.performModal.carrierStep.upsServices')}
                            value={this.selectedService ? this.selectedService : '11'}
                            disabled={this.hasSendCarrierRequest || this.hasGottenCarrierRequestResponse}
                            options={upsServices}
                            onChange={(value) => {
                                this.selectedService = value;
                                this.data.selected_service = value;
                            }}
                        />
                    )}
    
                </Form.Group>
                <Form.Group widths="equal" style={{ marginTop: '10px' }}>
                    <TargetTextArea fluid
                        label={t('workStation.production.performModal.carrierStep.deliveryAddress')}
                        target={this.salesOrder}
                        name="deliveryAddress"
                        onChange={(val) => {
                            this.salesOrder.deliveryAddress = val
                            this.data.deliveryAddress = val
                        }}
                        style={{ height: '110px' }}
                        disabled={this.hasSendCarrierRequest || this.hasGottenCarrierRequestResponse}
                    />
                </Form.Group>
                <Form.Group widths="equal">
                    <TargetTextInput
                        width={10}
                        label={t('workStation.production.performModal.carrierStep.country')}
                        value={this.recipientCountry ? this.recipientCountry : this.data.recipientCountry}
                        onChange={(val) => {
                            this.recipientCountry = val;
                            this.data.recipientCountry = this.recipientCountry;
                        }}
                        disabled={this.hasSendCarrierRequest || this.hasGottenCarrierRequestResponse}
                    />
                    <TargetTextInput
                        data-test-customer-email
                        fluid
                        label={t('workStation.production.performModal.carrierStep.email')}
                        name="email"
                        value={this.recipientEmail}
                        onChange={(val) => {
                            this.recipientEmail = val;
                            this.data.recipientEmail = this.recipientEmail;
                        }}
                        disabled={this.hasSendCarrierRequest || this.hasGottenCarrierRequestResponse}
                    />
                    <TargetTextInput
                        data-test-customer-phone
                        fluid
                        label={t('workStation.production.performModal.carrierStep.phoneNumber')}
                        value={this.recipientPhone}
                        onChange={(val) => {
                            this.recipientPhone = val;
                            this.data.recipientPhone = this.recipientPhone;
                        }}
                        disabled={this.hasSendCarrierRequest || this.hasGottenCarrierRequestResponse}
                    />
                </Form.Group>
            </Form>
        )
    }

    renderCarrierResponseTable(){
        return (
            <>
                <Header as='h1'>
                    Shipment input
                </Header>
                <CarrierRequestRowTable
                    carrierRequestRows={this.carrierRequestRows}
                    trackAndTraceCodes={this.response['barcodes']}
                    selectedPickLines={this.selectedPickLines}
                    emptyPickLines={()=>{
                        this.selectedPickLines = new Set();
                    }}
                    fetchCarrierRequest={()=>{this.fetchCarrierRequest(this.carrierRequestID)}}
                    pickLinesStore={this.pickOrderLines}
                />
            </>
        )
    }

    renderPickLinesDetails(){
        if (this.pickOrderLines === null) {
            return <></>
        }
        return (
            <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>
                            <Checkbox
                                checked={this.pickOrderLines.models.length === this.selectedPickLines.size}
                                onClick={action((e, { checked }) => {
                                    if(checked){
                                        this.pickOrderLines.models.forEach( (model) => {
                                            if(this.allocatedPickOrderLinesAmount(model.id) < model.productionRequest.quantity.d[0]){
                                                this.selectedPickLines.add(model.id)
                                            }
                                        });
                                    }
                                    else {
                                        this.selectedPickLines = new Set();
                                    }
                                    this.forceUpdate();
                                })}
                            />
                        </Table.HeaderCell>
                        <Table.HeaderCell>Code</Table.HeaderCell>
                        <Table.HeaderCell>{t('productionRequest.edit.stage.bom.articleName')}</Table.HeaderCell>
                        <Table.HeaderCell>#total</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {this.pickOrderLines.models.map((model) => {
                        return (
                            <Table.Row>
                                <Table.Cell>
                                    <Checkbox
                                        data-test-select={model.id}
                                        checked={this.selectedPickLines.has(model.id)}
                                        onClick={action((e, { checked }) => {  
                                            if(this.allocatedPickOrderLinesAmount(model.id) >= model.productionRequest.quantity.d[0]){
                                                return
                                            }
                                            if (checked) {
                                                this.selectedPickLines.add(model.id)
                                            }
                                            else {
                                                this.selectedPickLines.delete(model.id)
                                            }
                                            this.forceUpdate();
                                        })}
                                        disabled={this.allocatedPickOrderLinesAmount(model.id) >= model.productionRequest.quantity.d[0]} 
                                    />
                                </Table.Cell>
                                <Table.Cell data-test-code={model.id}>{model.salesOrderLine.articleType.getLabel()}</Table.Cell>
                                <Table.Cell data-test-article-name={model.id}>{model.salesOrderLine.articleType.name}</Table.Cell>
                                <Table.Cell data-test-total={model.id}>{this.allocatedPickOrderLinesAmount(model.id)}/{model.productionRequest.quantity.toString()}</Table.Cell>
                            </Table.Row>
                        )
                    })}
                </Table.Body>
            </Table>
        )
    }

    allocatedPickOrderLinesAmount(pickLineID){
        let amount = 0
        
        if(this.hasGottenCarrierRequestResponse && this.response != null){
            this.carrierRequest.carrierRequestRows.forEach((row)=>{
                row.carrierRequestRowsPickOrderLinks.forEach((CRRPOL)=>{
                    if(pickLineID === CRRPOL.pickOrderLine.id){
                        amount += CRRPOL.amount;
                    }
                });
            });
        }
        else {
            this.data.carrierData[0] && this.data.carrierData[0]['packageData'].forEach((requestRow)=>{
                if(pickLineID in requestRow['pickLines']){
                    amount += requestRow['pickLines'][pickLineID]
                }
            })
        }

        return amount
    }

    renderTabContent(){
        switch(this.activeTab){
            case 'shipmentDetails':
                return this.renderShipmentDetails()
            case 'pickLines':
                return this.renderPickLinesDetails()
            default:
                return this.renderShipmentDetails();
        }
    }

    renderShipmentInputContent(){
        if(this.hasGottenCarrierRequestResponse && this.response != null){
            return this.renderCarrierResponseTable();
        }

        return this.renderCarrierForm();
    }

    renderContent() {
        return (
            <CarrierStepContainer>
                <TabsContainer>
                    <div>
                        <Tab
                            data-test-shipment-details
                            active = {this.activeTab === 'shipmentDetails'}
                            onClick = {()=>{this.activeTab = 'shipmentDetails'}}
                        >
                            Shipment details
                        </Tab>
                        <Tab
                            data-test-pick-lines
                            active = {this.activeTab === 'pickLines'}
                            onClick = {()=>{this.activeTab = 'pickLines'}}
                        >
                            Pick Lines
                        </Tab>
                    </div>
                    <TabContentContainer>
                        {this.renderTabContent()}
                    </TabContentContainer>
                </TabsContainer>
                <ShipmentInputContainer>
                    <ShipmentInput>
                        {this.renderShipmentInputContent()}
                    </ShipmentInput>
                </ShipmentInputContainer>
            </CarrierStepContainer>
        )
    }
}
