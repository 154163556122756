import React from 'react'
import { observable } from 'mobx'
import { Model, Store } from 'store/Base'
import { Link } from 'react-router-dom'
import { Popup } from 'semantic-ui-react'
import { ProductionRequest } from './ProductionRequest'
import { BillOfMaterialVersion } from './BillOfMaterialVersion'
import { Warehouse } from './Warehouse'
import { ResourceAllocationStore } from '../feature/Allocation/store/ResourceAllocation'
import { PurchaseOrderLineStore } from './PurchaseOrderLine'
import { ExactGlobeProductionOrder } from './ExactGlobe/ProductionOrder'
import { ExactShopOrder } from './ExactShopOrder'
import { SalesOrderLine } from './SalesOrderLine'
import { PurchaseOrderStore } from './PurchaseOrder'
import { isFeatureFlagEnabled } from 'helpers/featureFlags'
import { StyledIcon, StyledLabel } from "../component/Labels";



export class ProductionOrder extends Model {
  static backendResourceName = 'production_order'
  static idPrefix = 'PR'
  static idColor = 'orange'
  static idIcon = 'handyman'
  static omitFields = ['unallocated', 'quantityStatus', 'unallocatedFuture']

  @observable id = null
  @observable status = 'open'
  @observable description = ''
  @observable unallocated = 0
  @observable quantityStatus = 'open'
  @observable erpId = null
  @observable erpStatus = 'open'

  @observable unallocatedFuture = 0;

  relations() {
    return {
      productionRequest: ProductionRequest,
      billOfMaterialVersion: BillOfMaterialVersion,
      warehouse: Warehouse,
      resourceAllocations: ResourceAllocationStore,
      purchaseOrderLines: PurchaseOrderLineStore,
      purchaseOrders: PurchaseOrderStore,
      exactGlobeProductionOrder: ExactGlobeProductionOrder,
      exactShopOrder: ExactShopOrder,
      salesOrderLine: SalesOrderLine,
      materialPlan: BillOfMaterialVersion,
    }
  }

  getUrl() {
    return `/planning/production-request/overview?.production_order.id=${this.id}`
  }

  getErpLink() {
    if (this.erpId === null) {
      return
    }

    return (
      <Popup
        trigger={
          <StyledLabel
            color={'red'}
            data-test-tag-linked-production-order={this.id}
            as={Link}
            to={this.getUrl()}
            style={{ textDecoration: 'none', margin: '0' }}
            target={'_blank'}
          >
            <StyledIcon name={ProductionOrder.idIcon} />
            {this.erpId}
          </StyledLabel>
        }
        content={<>{t('productionRequest.overview.productionOrderNumber')}:{' '} {this.erpId || this.id}</>}
      />
    )
  }

  getLinkedAllocatedSalesOrders() {
    const linkedSas = []

    if (!this.salesOrderLine?.salesOrder?.isNew) {
      linkedSas.push(this.salesOrderLine.salesOrder)
    }

    if (isFeatureFlagEnabled('allocations') && this.resourceAllocations?.length > 0) {
      this.resourceAllocations.filter(ra => ra.salesOrderLine && !ra.salesOrderLine.isNew && ra.salesOrderLine.salesOrder.orderNumberErp !== null).forEach((resourceAlloc) => {
        if (!linkedSas.map(sa => sa.id).includes(resourceAlloc.salesOrderLine.salesOrder.id)) {
          linkedSas.push(resourceAlloc.salesOrderLine.salesOrder)
        }
      })

    }

    return linkedSas.map(sa => sa.getIdLabel(false, { progressViewLink: true }))
  }

  getMiniTag() {
    return window.viewStore.tracyTagSize === 'mini'
  }

  async release() {
    try {
      const res = await this.wrapPendingRequestCount(this.api.post(`${this.url}release/`))
      this.status = 'released'
      return res
    } catch (err) {
      if (err.response) {
        const valErrors = this.api.parseBackendValidationErrors(err.response)
        if (valErrors) {
          this.parseValidationErrors(valErrors)
        }
      }
      throw err
    }
  }

  async unrelease() {
    try {
      const res = await this.wrapPendingRequestCount(this.api.post(`${this.url}unrelease/`))
      this.status = 'open'
      return res
    } catch (err) {
      if (err.response) {
        const valErrors = this.api.parseBackendValidationErrors(err.response)
        if (valErrors) {
          this.parseValidationErrors(valErrors)
        }
      }
      throw err
    }
  }
}

export class ProductionOrderStore extends Store {
  static backendResourceName = 'production_order'

  Model = ProductionOrder
}
