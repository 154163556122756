import PropTypes, { string } from 'prop-types'
import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { Table, Popup } from 'semantic-ui-react'
import { CarrierRequestRowPickOrderLink, CarrierRequestRowStore } from '../../../../store/CarrierRequest'
import { IconButton } from 'spider/semantic-ui/Button'
import { PickLinesLinkContainer, IconButtonContainer, TextPickLinesHoverContainer, PickLinesAmountContainer, PickLinesHoverContainer } from './helper'
import { showDeleteNotification, showSaveNotification } from '../../../../helpers/notification'
import { OutShipmentLineStore } from '../../../../store/OutShipmentLine'

@observer
export default class CarrierRequestRowTable extends Component {
    static propTypes = {
        carrierRequestRows: PropTypes.instanceOf(CarrierRequestRowStore).isRequired,
        trackAndTraceCodes: PropTypes.arrayOf(string).isRequired,
        selectedPickLines: PropTypes.instanceOf(Set).isRequired,
        emptyPickLines: PropTypes.func.isRequired,
        fetchCarrierRequest: PropTypes.func.isRequired,
        pickLinesStore: PropTypes.instanceOf(OutShipmentLineStore),
    }

    allocatedPickOrderLinesAmount(pickLineID){
        const { carrierRequestRows } = this.props;
        let amount = 0

        carrierRequestRows.forEach((row)=>{
            row.carrierRequestRowsPickOrderLinks.forEach((CRRPOL)=>{
                if(pickLineID === CRRPOL.pickOrderLine.id){
                    amount += CRRPOL.amount;
                }
            });
        });
    
        return amount
    }

    render(){
        const { carrierRequestRows, trackAndTraceCodes, emptyPickLines, selectedPickLines, fetchCarrierRequest, pickLinesStore } = this.props
        return (
            <Table basic='very'>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Line</Table.HeaderCell>
                        <Table.HeaderCell>{t('workStation.production.performModal.carrierStep.pickShippingUnit')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('workStation.production.performModal.carrierStep.weigth')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('workStation.production.performModal.carrierStep.length')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('workStation.production.performModal.carrierStep.width')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('workStation.production.performModal.carrierStep.height')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('workStation.production.performModal.carrierStep.volume')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('workStation.production.performModal.carrierStep.trackTrace')}</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {carrierRequestRows.models.map((model) => {
                        return (
                            <>
                                <Table.Row
                                    data-test-carrier-request-row = {model.id}
                                >
                                    <Table.Cell data-test-collo-nr={model.colloNr} >{model.colloNr}</Table.Cell>
                                    <Table.Cell data-test-shipping-unit={model.colloNr} >{model.shippingUnit}</Table.Cell>
                                    <Table.Cell data-test-weigth={model.colloNr}>{model.weigth} kg</Table.Cell>
                                    <Table.Cell data-test-length={model.colloNr} >{model.length} cm</Table.Cell>
                                    <Table.Cell data-test-width={model.colloNr}>{model.width} cm</Table.Cell>
                                    <Table.Cell data-test-height={model.colloNr}>{model.height} cm</Table.Cell>
                                    <Table.Cell data-test-volume={model.colloNr}>{(model.length * model.width * model.height)/ 1000000} m3</Table.Cell>
                                    <Table.Cell data-test-track-and-trace={model.colloNr}>{trackAndTraceCodes[model.colloNr]}</Table.Cell>
                                </Table.Row>
                                <PickLinesLinkContainer>
                                    <IconButton
                                        data-test-link-pick-lines={model.id}
                                        name="chain"
                                        onClick={() => {
                                            // Add all selected pick lines
                                            // eslint-disable-next-line
                                            for (const pickLine of selectedPickLines) {
                                                // Check if there already exists a carrierRequestPickorderLink
                                                let newCRRPOL = null
                                                model.carrierRequestRowsPickOrderLinks.models.forEach(otherCRRPOL => {
                                                    if(otherCRRPOL.pickOrderLine.id === pickLine){
                                                        newCRRPOL = otherCRRPOL;
                                                    }
                                                });

                                                // If no link exist, create one
                                                if(newCRRPOL === null){
                                                    newCRRPOL = new CarrierRequestRowPickOrderLink({},{relations:['pickOrderLine','carrierRequestRow'] })
                                                    newCRRPOL.amount = 0
                                                    newCRRPOL.pickOrderLine = pickLinesStore.get(pickLine);
                                                    newCRRPOL.carrierRequestRow = model
                                                }
                                                newCRRPOL.amount++
                                                newCRRPOL.save().then(()=>{
                                                    showSaveNotification();
                                                });
                                            }
                                            fetchCarrierRequest();
                                            emptyPickLines();
                                        }}
                                    />
                                    {model.carrierRequestRowsPickOrderLinks.models.length === 0 && (
                                        <>{t('workStation.production.performModal.carrierStep.link')}</>
                                    )}
                                    {model.carrierRequestRowsPickOrderLinks.models.map((CRRPOL)=>{
                                        return (
                                            <> 
                                                {this.renderPicklines(CRRPOL)}
                                            </>
                                        )
                                    })}
                                </PickLinesLinkContainer>
                            </>
                        )
                    })}
                </Table.Body>
            </Table>
        )
    }

    renderPicklines(CRRPOL){
        return (
            <Popup
                trigger={
                    <PickLinesAmountContainer
                        data-test-carrier-request-row-pick-line-link={CRRPOL.id}
                    >
                        {CRRPOL.amount}x 
                        {CRRPOL.pickOrderLine.salesOrderLine.articleType.getLabel()}
                    </PickLinesAmountContainer>
                }
                flowing 
                hoverable
                on='click'
                pinned
            >
                <PickLinesHoverContainer>
                    <IconButtonContainer>
                        <IconButton
                            size='huge'
                            fluid
                            data-test-click-min-button
                            name="minus"
                            onClick={()=>{
                                CRRPOL.amount--; 
                                if (CRRPOL.amount === 0) {
                                    CRRPOL.delete().then(()=>{
                                        showDeleteNotification();
                                    });
                                }
                                else {
                                    CRRPOL.save().then(()=>{
                                        showSaveNotification();
                                    });
                                }
                            }}
                        />
                    </IconButtonContainer>
                    <TextPickLinesHoverContainer>
                        {CRRPOL.amount}
                    </TextPickLinesHoverContainer>
                    <IconButtonContainer>
                        <IconButton
                            size='huge'
                            fluid
                            data-test-click-plus-button
                            name="plus"
                            onClick={()=>{
                                if(this.allocatedPickOrderLinesAmount(CRRPOL.pickOrderLine.id) < CRRPOL.pickOrderLine.productionRequest.quantity.d[0]){
                                    CRRPOL.amount++;
                                    CRRPOL.save().then(()=>{
                                        showSaveNotification();
                                    });
                                }
                            }}
                        />
                    </IconButtonContainer>
                </PickLinesHoverContainer>
            </Popup>
        )

    }

}