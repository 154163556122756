import React from 'react'
import { computed, reaction, action } from 'mobx'
import { ProductionRequestProgress } from 'container/Progress/Overview'
import AdminOverview from 'component/AdminOverview'
import { format } from 'helpers/date'
import { Link } from 'react-router-dom'
import { Icon, Button, Table, Dimmer, Loader } from 'semantic-ui-react'
import FrontendSortTableHeader from 'component/FrontendSortTableHeader'
import { Body2, Caption1, COLORS } from 'component/PerformanceStyles';
import LiveProgress from 'component/Progress/LiveProgress'
import { getArticleTypeName } from "../../helpers/productionRequest";
import styled from 'styled-components'
import Decimal from 'decimal.js'
import { humanReadable } from 'helpers/decimal'
import { ProductionRequestStore } from 'store/ProductionRequest'
import { serializeWheres } from 'spider/semantic-ui/Target'
import { BASE_RELATIONS, BASE_WHERES } from 'screen/Progress/Views/Default';
import { observer } from 'mobx-react'

const StyledIcon = styled(Icon)`
  :before {
    margin-right: 0.2em !important;
  }
`

const LinesLoader = styled(Loader)`
  display: inline-block !important;
  width: 1em !important;
  height: 1em !important;

  &::before, &::after {
    width: 1em !important;
    height: 1em !important;
  }
`;

const DetailsCell = styled(({ children, ...props }) => <Table.Cell data-test-details {...props}><div>{children}</div></Table.Cell>)`
  padding: 0 !important;
  > div {
    display: flex;
    border-bottom: 8px solid ${COLORS.grey800};
    border-right: 8px solid ${COLORS.grey800};
    &::before {
      z-index: 1;
      writing-mode: vertical-rl;
      text-orientation: mixed;
      text-align: center;
      color: ${COLORS.grey50};
      content: 'LINES (${({ lines }) => lines})';
      transform: rotate(180deg);
      background: ${COLORS.grey800};
      margin-bottom: -0.0625em;
    }
    > table {
      flex: 1 1 0;
      border: unset !important;
      > thead > tr:first-child {
        > th {
          background-color: ${COLORS.grey800};
          color: ${COLORS.grey50};
        }
        > th:first-child { border-top-left-radius: 0 !important; }
        > th:last-child { border-top-right-radius: 0 !important; }
      }
      > tbody > tr > td{
        background-color: ${({ warning }) => warning ? COLORS.warning : COLORS.notWarning};
      }
      > tbody > tr:last-child {
        > th:first-child { border-bottom-left-radius: 0 !important; }
        > th:last-child { border-bottom-right-radius: 0 !important; }
      }
    }
  }
`

function isLocationTransfer(productionRequest) {
  return !productionRequest.warehouseTransfer.isNew && productionRequest.warehouseTransfer.type === 'Location transfer'
}

@observer
export default class ProgressLinesDetails extends AdminOverview {
  store = new ProductionRequestStore({
    relations: [
      ...BASE_RELATIONS,

      'nestRequest',

      'inShipmentLine.purchaseOrderLine.resourceAllocations.salesOrderLine.salesOrder.exactSalesOrder', // to display erp link
      'inShipmentLine.purchaseOrderLine.salesOrderLine.salesOrder',
      'inShipmentLine.purchaseOrderLine.project',
      'inShipmentLine.purchaseOrderLine.exactPurchaseOrderLine',

      'outShipmentLine.salesOrderLine.resourceAllocations.purchaseOrderLine.purchaseOrder',
      'outShipmentLine.salesOrderLine.purchaseOrderLines.purchaseOrder',
      'outShipmentLine.salesOrderLine.project',
      'outShipmentLine.salesOrderLine.exactSalesOrderLine',

      'warehouseTransferLine.storageLocationFrom',
      'warehouseTransferLine.storageLocationTo',
    ],
    params: {
      include_annotations: '*,out_shipment_line.quantity_received_erp',
      '.superrequest': this.props.productionRequest.id,
      where: serializeWheres(BASE_WHERES),
    }
  })
  bindUrlParams = false

  TableHeader = FrontendSortTableHeader

  constructor(...args) {
    super(...args)

    // Store is supplied with the sub production request.
    if (this.props.store !== undefined) {
      this.store = this.props.store
    }
  }

  @action fetch() {
    const params = this.store.params

    // Do not fetch if store of sub production requests is suuplied.
    if (this.props.store !== undefined) {
      return;
    }
    try {
      return super.fetch()
    } finally {
      this.store.params = params
    }
  }

  performanceSubscriptions = {}

  componentDidMount() {
    super.componentDidMount()

    this.isWorkStationReaction = reaction(
      () => (
        this.workStationCode
          ? this.store.models.map(({ id }) => id).sort().join(',') // In a sorted string so that we only update when its really different
          : '*'

      ),
      (ids) => {
        ids = ids === '' ? [] : ids.split(',')
        // Unsubscribe from old subscriptions

        // eslint-disable-next-line
        for (const [id, subscription] of Object.entries(this.performanceSubscriptions)) {
          if (!ids.includes(id)) {
            subscription.unsubscribe()
            delete this.performanceSubscriptions[id]
          }
        }
        // Subscribe to new subscriptions
        // eslint-disable-next-line
        for (const id of ids) {
          if (this.performanceSubscriptions[id] === undefined) {
            this.performanceSubscriptions[id] = this.store.subscribeToPerformances(id === '*' ? '*' : parseInt(id), { removeFinalized: true })
          }
        }
      },
      { fireImmediately: true },
    )
  }

  componentWillUnmount() {
    super.componentWillUnmount()

    // eslint-disable-next-line
    for (const subscription of Object.values(this.performanceSubscriptions)) {
      subscription.unsubscribe()
    }
  }

  tableProps() {
    return { 'data-test-progress-lines': true }
  }

  rowProps(productionRequest) {
    return { 'data-test-production-request': productionRequest.id }
  }

  @computed get settings() {
    const { productionRequest: mainPr } = this.props
    return [
      {
        attr: (productionRequest) => {
          // display related purchaseOrder of pickLine
          let pickLinePO = null
          if (!mainPr.outShipment.isNew) {
            pickLinePO = productionRequest?.outShipmentLine?.salesOrderLine?.resourceAllocations?.map(ra => ra.purchaseOrderLine?.purchaseOrder?.getErpLink())
          }
          return (
            <b data-test-article-type-name>
              {(!productionRequest.isNew) &&
                productionRequest.getLink({
                  hover: (
                    <>
                      {t('productionRequest.overview.productionRequestID')}: {productionRequest && productionRequest.id}{' '}
                      <br />
                      {t('productionRequest.field.project.label')}:{' '}
                      {productionRequest.project && productionRequest.project.code}
                    </>
                  ),
                })
              }
              {!productionRequest.processVersion.batchType.articleType.isNew && productionRequest.processVersion.batchType.articleType.getLink()}
              {productionRequest?.inShipmentLine?.purchaseOrderLine?.getLinkedAllocatedSalesOrders()}
              {pickLinePO}
              {!productionRequest.nestRequest.isNew && productionRequest.nestRequest.getLabel()}
            </b>
          )
        },
        label: t('productionRequest.overview.order'),
        cellProps: { collapsing: true, singleLine: true, 'data-test-progress-line-tags': true },
      },
      ...!mainPr.outShipment.isNew ? [{
        // T41127: for pick order lines, show quantity received on ERP lines
        attr: (productionRequest) => {
          if (!productionRequest?.outShipmentLine?.salesOrderLine?.resourceAllocations?.models.some(ra => ra.purchaseOrderLine?.purchaseOrder)) {
            // do not show the icons when there is no link
            return <></>
          }

          const quantityReceivedErp = Decimal(productionRequest.outShipmentLine.quantityReceivedErp)
          let statusProps = { fitted: true, size: 'big' }

          if (quantityReceivedErp.gte(productionRequest.quantity)) {
            // has been fully gathered
            statusProps = { ...statusProps, color: 'green', name: 'circle-check' }
          } else if (quantityReceivedErp.gt(Decimal(0))) {
            // gathering in progress
            statusProps = { ...statusProps, color: 'blue', name: 'circle-progress' }
          } else {
            // nothing collected yet
            statusProps = { ...statusProps, color: 'orange', name: 'circle-pause' }
          }

          return <>
            <StyledIcon data-test-status {...statusProps} />
            {humanReadable(quantityReceivedErp)}
          </>
        },
        cellProps: { collapsing: true, 'data-test-quantity-received-erp': true },
        label: t('purchaseOrderLine.field.erpLines.label')
      }] : [],
      {
        attr: (productionRequest) => {
          let notes = null
          if (!productionRequest.outShipmentLine.isNew) {
            notes = productionRequest.outShipmentLine.salesOrderLine.notes
          } else if (!productionRequest.inShipmentLine.isNew) {
            notes = productionRequest.inShipmentLine.purchaseOrderLine.salesOrderLine.notes
          }
          return !productionRequest.processVersion.batchType.articleType.isNew && (
            <>
              <Body2>
                {getArticleTypeName(productionRequest)}
              </Body2>
              {notes && <Caption1>{notes}</Caption1>}
            </>
          )
        },
        cellProps: { collapsing: true, singleLine: true, 'data-test-article-type-description': true },
        label: t('productionRequest.field.articleTypeDescription.label')
      },
      ...isLocationTransfer(mainPr) ? [{
        attr: (productionRequest) => {
          const details = {
            type: mainPr.warehouseTransfer.type,
            from: productionRequest.warehouseTransferLine.storageLocationFrom.code,
            to: productionRequest.warehouseTransferLine.storageLocationTo.code
          }
          return (
            <>
              <Body2>{details.type}</Body2>
              {details.from && details.to && (
                <Caption1>From: {details.from}<br />To: {details.to}</Caption1>
              )}
            </>
          )
        },
        cellProps: { collapsing: true, 'data-test-location-transfer-details': true },
        label: t('productionRequest.overview.information'),
      }] : [],
      {
        attr: (productionRequest) => format(productionRequest.startAt),
        cellProps: { collapsing: true, singleLine: true, 'data-test-start-at': true },
        label: t('productionRequest.field.startAt.label'),
        sortKey: 'start_at',
      },
      {
        attr: (productionRequest) => (
          <LiveProgress withTotal productionRequest={productionRequest} />
        ),
        label: '#' + t('productionRequest.field.quantity.label'),
        collapsing: true,
      },
      {
        attr: (productionRequest) => (
          <ProductionRequestProgress includeQuantityDone
            productionRequest={productionRequest}
            onSelect={
              window.viewStore.isWorkStation
                ? () => this.props.history.push(`/operations/production-request/${productionRequest.id}/perform?prev=${encodeURIComponent(window.location.pathname + window.location.search)}`)
                : undefined
            }
            workStationCode={window.viewStore.workStationCode}
            groupBy={this.groupBy}
            showLabels={this.props.showLabels}
          />
        ),
        label: t('productionRequest.overview.processSteps'),
        collapsing: true,
      },
      window.viewStore.isWorkStation && '',
    ]
  }

  buttons = [
    (productionRequest) => window.viewStore.isWorkStation && (
      <Button data-test-production-request-perform={productionRequest.id}
        primary
        icon="play"
        size="large"
        as={Link}
        to={`/operations/production-request/${productionRequest.id}/perform?prev=${encodeURIComponent(window.location.pathname + window.location.search)}`}
      />
    ),
  ]

  renderBody() {
    return this.renderOverviewTable()
  }

  renderLoader() {
    return (
      <div style={{ position: 'relative', margin: '0 auto' }}>
        <Dimmer inverted active><LinesLoader inline='centered' size="big" /></Dimmer>
      </div>
    )
  }

  render() {
    return (
      <DetailsCell warning={this.props.warning} lines={this.store.length} colSpan={this.props.colSpan}>
        {this.store.isLoading ? this.renderLoader() : this.renderBody()}
      </DetailsCell>
    )
  }
}
