import {observable, computed} from 'mobx'
import {isFeatureFlagEnabled} from 'helpers/featureFlags'
import {Model, Store, Casts} from 'store/Base'
import {SalesOrder} from './SalesOrder'
import {ArticleType} from './ArticleType'
import {Project} from './Project'
import {ExactSalesOrderLine} from './ExactSalesOrderLine'
import {NavisionSalesOrderLine} from './NavisionSalesOrderLine'
import {NavisionProjectLine} from './NavisionProjectLine'
import {ResourceAllocationStore} from '../feature/Allocation/store/ResourceAllocation'
import {OutShipmentLineStore} from './OutShipmentLine'
import {ArticleTypeWarehouse} from './ArticleTypeWarehouse'
import {PurchaseOrderLineStore} from './PurchaseOrderLine'
import Decimal from 'decimal.js'
import {getAssemblyBom} from "../feature/Allocation/helpers/assemblyBom";

export const STATUS_OPEN = 'open'
export const STATUS_PARTIAL = 'partial'
export const STATUS_COMPLETE = 'complete'
export const STATUS_CANCELED = 'canceled'
export const STATUSES = [STATUS_OPEN, STATUS_PARTIAL, STATUS_COMPLETE, STATUS_CANCELED]

export class SalesOrderLine extends Model {
  static backendResourceName = 'sales_order_line'
  static omitFields = ['quantityDeliveredErp', 'quantityReturnedErp', 'batchCount', 'unallocated', 'quantityAvailable', 'quantityAvailableUnallocated']

  @observable id = null
  @observable quantity = Decimal(0)
  @observable unitPrice = 0
  @observable netPrice = 0
  @observable productionDueDate = null
  @observable shippable = true
  @observable deliveryDate = null
  @observable itemCode = null
  @observable description = ''
  @observable notes = ''
  @observable number = 0
  @observable deliveryStatus = STATUS_OPEN

  @observable quantityDeliveredErp = Decimal(0)
  @observable quantityReturnedErp = Decimal(0)
  @observable batchCount = 0
  @observable unallocated = Decimal(0)
  @observable amountDelivered = 0
  @observable quantityAvailable = Decimal(0)
  @observable quantityAvailableUnallocated = Decimal(0)
  @observable toPick = Decimal(0)

  @computed get allocated() {

    if (this.isAssemblyItem) {
      const assemblyBom = getAssemblyBom(this);
      if (!assemblyBom) {
        return Decimal(0)
      }

      // Look at all the allocations, and see to how many items of the salesorderline it can fulfil. Take the lowest amount to display as allocated
      const ratioValues = this.resourceAllocations.models
        .filter(allocation => !allocation.articleType.isNew)
        .filter(allocation => allocation.articleType.id !== this.articleType.id)
        .map(allocation => {
          const allocationArticleType = allocation.articleType
          
          // The quantity of the article type on the BOM
          const bomQuantity = assemblyBom.items?.models.find(item => item?.articleType?.id === allocationArticleType.id)?.quantity
          
          // The amount of actual allocations this allocation quantity can fulfil
          const allocationEquivalent = bomQuantity ? allocation.quantity/bomQuantity : 0
          return Decimal(Math.floor(allocationEquivalent))
        })
      if (ratioValues.length === 0) {
        return Decimal(0)
      }
      return Decimal(Math.min(...ratioValues))
    }

    return this.resourceAllocations.models.reduce((allocated, {quantity}) => allocated.add(quantity), new Decimal(0))
  }

  @computed get hasUnallocatedDeficit() {
    return isFeatureFlagEnabled('allocations') && this.articleTypeWarehouse.unallocatedDeficit > 0 && this.allocated > 0
  }

  allocateFromWarehouseStock() {
    return this.api.post(`${this.url}allocate_from_warehouse_stock/`)
  }

  unallocateFromWarehouseStock() {
    return this.api.post(`${this.url}unallocate_from_warehouse_stock/`)
  }

  createPickOrder() {
    return this.api.post(`${this.url}create_pick_order/`)
  }

  relations() {
    return {
      salesOrder: SalesOrder,
      articleType: ArticleType,
      project: Project,
      exactSalesOrderLine: ExactSalesOrderLine,
      navisionSalesOrderLine: NavisionSalesOrderLine,
      navisionProjectLine: NavisionProjectLine,
      outShipmentLines: OutShipmentLineStore,
      resourceAllocations: ResourceAllocationStore,
      articleTypeWarehouse: ArticleTypeWarehouse,
      purchaseOrderLines: PurchaseOrderLineStore,
    }
  }

  casts() {
    return {
      quantity: Casts.decimal,
      productionDueDate: Casts.date,
      quantityDeliveredErp: Casts.decimal,
      quantityReturnedErp: Casts.decimal,
      unallocated: Casts.decimal,
      quantityAvailable: Casts.decimal,
      quantityAvailableUnallocated: Casts.decimal,
      deliveryDate: Casts.date,
    }
  }

  /**
   * Whether there is at least one BatchType 'sell' for this line's article,
   * which is necessary for creating OutShipment orders.
   */
  @computed get hasSellProcess() {
    return this.articleType.batchTypes.filter(({ type }) => type === 'sell').length > 0
  }

  @computed get currentWarehouse() {
    return this.articleType.storageLocations.reduce(
      (total, { warehouse, stock }) => warehouse.id === this.warehouse.id ? total + stock : total,
      0,
    )
  }

  @computed get currentTotal() {
    return this.articleType.storageLocations.reduce(
      (total, { stock }) => total + stock,
      0,
    )
  }

  @computed get isAssemblyItem() {
    return this.articleType.billOfMaterialVersions.models.some((p) => p.isAssemblyBom)
  }
}

export class SalesOrderLineStore extends Store {
  static backendResourceName = 'sales_order_line'
  Model = SalesOrderLine
}
