import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { action, observable } from 'mobx'
import { observer } from 'mobx-react'
import { Button, Form, Modal } from 'semantic-ui-react'
import { TargetTextInput } from 'spider/semantic-ui/Target'
import RightDivider from 'spider/component/RightDivider'
import { Batch } from 'store/Batch'
import { showSaveNotification, showNotification } from 'helpers/notification'


// 'Rework Batch' button on Batch Overview
@observer
export default class BatchReworkModal extends Component {
  static propTypes = {
    target: PropTypes.instanceOf(Batch).isRequired,
    trigger: PropTypes.node,
  }

  @observable open = false
  @observable reason = ''

  constructor(...args) {
    super(...args)

    this.onOpen = this.onOpen.bind(this)
    this.onClose = this.onClose.bind(this)
    this.onChangeReason = this.onChangeReason.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }

  onOpen() {
    this.open = true
  }

  onClose() {
    this.open = false
  }

  onChangeReason(value) {
    this.reason = value
  }

  @action async onSubmit() {
    const batch = this.props.target
    // Get performance with largest created at
    const performance = batch.performances.models.filter((performance) => !performance.reworked).sort((p1, p2) => p1.createdAt.isBefore(p2.createdAt))[0]

    if (performance) {
      return performance.reset(this.reason).then((res) => {
        this.onClose()
        return batch.fetch().then(showSaveNotification)
      })
    } else {
      this.onClose()
      return showNotification({
        key: 'noPerformance',
        error: false,
        dismissAfter: 4000,
        message: 'No performance has been found for this batch.',
      })
    }
  }


  render() {
    const { trigger } = this.props

    return (
      <Modal closeIcon data-test-batch-rework-modal
        onClose={this.onClose}
        onOpen={this.onOpen}
        open={this.open}
        trigger={trigger}
      >
        <Modal.Header>{t('performanceResetModal.title')}</Modal.Header>
        <Modal.Content>
          <Form onSubmit={this.onSubmit}>
            <TargetTextInput
              autoFocus
              data-test-rework-reason
              label={t('performanceResetModal.reason')}
              name="reason"
              value={this.reason}
              onChange={this.onChangeReason}
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <RightDivider />
          <Button
            primary
            data-test-confirm-rework-button
            icon="check"
            labelPosition="left"
            content={t('performanceResetModal.resetButton')}
            onClick={this.onSubmit}
          />
        </Modal.Actions>
      </Modal>
    )
  }
}
