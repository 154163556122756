import {computed, observable} from 'mobx'
import { Model, Store } from 'store/Base'
import {Integration} from "./Integration";
import {sum} from "lodash";
import { Performance } from './Performance'
import {SalesOrder} from "./SalesOrder";
import { OutShipmentLine } from './OutShipmentLine';

export class CarrierRequest extends Model {
  static backendResourceName = 'carrier_request'

  @observable id = null
  @observable recipientName = '';
  @observable recipientStreet = '';
  @observable recipientHouseNumber = '';
  @observable recipientPostalCode = '';
  @observable recipientCity = '';
  @observable recipientCountry = ''
  @observable recipientEmail = '';
  @observable recipientPhoneNumber = '';
  @observable number = '';

  /**
   * Calculate total weight of this request
   */
  @computed get totalWeight() {
    return sum(this.carrierRequestRows.map(row => row.weigth))
  }

  relations() {
    return {
      carrier: Integration,
      carrierRequestRows: CarrierRequestRowStore,
      performance: Performance
    }
  }
}

export class CarrierRequestStore extends Store {
  Model = CarrierRequest
  static backendResourceName = 'carrier_request'
}

export class CarrierRequestRow extends Model {
  static backendResourceName = 'carrier_request_row';

  @observable colloNr = '';
  @observable shippingUnit = '';
  @observable length = '';
  @observable width = '';
  @observable height = '';
  @observable weigth = '';
  @observable reference = '';

  relations() {
    return {
      salesOrder: SalesOrder,
      carrierRequestRowsPickOrderLinks: CarrierRequestRowPickOrderLinkStore,
    }
  }


}

export class CarrierRequestRowStore extends Store {
  Model = CarrierRequestRow
  static backendResourceName = 'carrier_request_row';
}

export class CarrierRequestRowPickOrderLink extends Model {
  static backendResourceName = 'carrier_request_row_pick_order_link'

  @observable amount = null;

  relations() {
    return {
      carrierRequestRow: CarrierRequestRow,
      pickOrderLine: OutShipmentLine,
    }
  }
}

export class CarrierRequestRowPickOrderLinkStore extends Store {
  Model = CarrierRequestRowPickOrderLink
  static backendResourceName = 'carrier_request_row_pick_order_link'
}