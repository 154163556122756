import { observable, computed } from 'mobx'
import { Model, Store, Casts } from 'store/Base'
import { Batch } from './Batch'
import { LoadCarrierWeighingStore } from 'store/LoadCarrierWeighing'
import { MetafieldStore } from 'store/Metafield'
import { MetavalueStore } from 'store/Metavalue'
import Decimal from 'decimal.js'

export class LoadCarrier extends Model {
  static backendResourceName = 'load_carrier'
  static omitFields = ['reweighDate', 'needsReweighing']

  static TYPES = ['pallet_euro', 'pallet_pool', 'container', 'gitterbox', 'carton_box', 'stackbox']
  static WEIGHT_UNITS = ['kg', 'g', 'mg']

  static idColor = 'blue'
  static idIcon = 'dolly flatbed'

  @computed get _id() {
    return this.serialNumber
  }

  getUrl() {
    return `/assets/load-carrier/${this.id}/edit`
  }

  @observable id = null
  @observable name = ''
  @observable serialNumber = ''

  @observable weight = Decimal(0)
  @observable weightUnit = 'kg'

  @observable reweighDays = null

  @observable reweighDate = null
  @observable needsReweighing = false

  relations() {
    return {
      batch: Batch,
      weighings: LoadCarrierWeighingStore,
      metafields: MetafieldStore,
      metavalues: MetavalueStore,
    }
  }

  casts() {
    return {
      weight: Casts.decimal,
      reweighDate: Casts.date,
    }
  }

  toBackendAll({ nestedRelations: { metavalues, ...nestedRelations } = {}, ...options } = {}) {
    const { data: [data], relations } =  super.toBackendAll({ nestedRelations, ...options })

    if (metavalues !== undefined) {
      delete data.metavalues

      // eslint-disable-next-line
      for (const metavalue of this.metavalues.models) {
        const { data: [subdata], relations: subrelations } = metavalue.toBackendAll({ nestedRelations: metavalues })
        delete subdata.metafield
        data[`metafield(${metavalue.metafield.id})`] = subdata

        // eslint-disable-next-line
        for (const [rel, reldata] of Object.entries(subrelations)) {
          if (relations[rel] === undefined) {
            relations[rel] = []
          }
          relations[rel].push(...reldata)
        }
      }
    }

    return { data: [data], relations }
  }
}

export class LoadCarrierStore extends Store {
  Model = LoadCarrier
  static backendResourceName = 'load_carrier'
}
