import { ItemButton } from 'component/AdminOverview'
import { print, getPrinters, getPrinterType } from 'helpers/print'
import React, { Component } from 'react'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import { Modal, Button, Form } from 'semantic-ui-react'
import { IconButton } from 'spider/semantic-ui/Button'
import RightDivider from 'spider/component/RightDivider'
import { t } from '../i18n'
import { TargetNumberInput } from 'spider/semantic-ui/Target'
import PrintersList from 'component/PrintersList'
import getGlobalValue from 'helpers/getGlobalValue'
import styled from "styled-components";


const CollectedSpan = styled.span`
  display: flex;
  min-width: 1000px;
`


@observer
export default class PrintButtonModal extends Component {
  static propTypes = {
    getInstructions: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    trigger: PropTypes.node,
    copies: PropTypes.number,
    alterButtons: PropTypes.bool,
  }

  static defaultProps = {
    trigger: null,
    copies: 1,
  }

  constructor(props) {
    super(props)
    const {copies} = this.props
    this._copies = copies
  }

  @observable print = null
  @observable _copies = 1

  render() {
    const { getInstructions, title, trigger, alterButtons, ...props } = this.props

    const numberOfCopiesInput = (
      <TargetNumberInput data-test-number-of-copies
        fluid
        style={{minWidth:'630px'}}
        value={this._copies.toString()}
        onChange={(value) => {
          if (value !== '') {
            this._copies = parseInt(value)
          }
        }}
      />
    )

    const numberOfCopies = alterButtons ? (
      <CollectedSpan>
        <Button
          icon='minus'
          onClick={() => this._copies--}
          data-test-alter-copies-minus
        />
        {numberOfCopiesInput}
        <Button
          icon='plus'
          onClick={() => this._copies++}
          data-test-alter-copies-plus
        />
      </CollectedSpan>
    ) : (
      <>
        {numberOfCopiesInput}
      </>
    )

    return (
      <Modal data-test-print-modal closeIcon
        size="small"
        trigger={trigger !== null ? trigger :
          <ItemButton
            icon="print"
            label={t('printModal.title', { item: title })}
            {...props}
          />
        }
        open={this.print !== null}
        onOpen={async () => {
          const [printerType, allPrinters, instructions] = await Promise.all([
            getGlobalValue('printer_model'),
            getPrinters(),
            getInstructions(),
          ])

          const printers = allPrinters.filter((printer) => getPrinterType(printer) === printerType)
          const printer = printers.length === 1 ? printers[0] : null

          this.print = { printerType, instructions, printers, printer }
        }}
        onClose={() => this.print = null}
      >
        <Modal.Header>{t('printModal.title', { item: title })}</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Field>
              <label>{t('printModal.selectPrinter')}</label>
              {this.selectPrinterModal()}
            </Form.Field>
            <label><b>{t('printModal.copies')}</b></label>
            {numberOfCopies}
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <RightDivider />
          <Button data-test-print-button primary
            labelPosition="left" icon="print"
            content={t('printModal.printButton')}
            disabled={this.print === null || this.print.printer === null}
            onClick={async () => {
              await print(this.print.printer, this.print.instructions, { copies: this._copies })
              this.print = null
            }}
          />
        </Modal.Actions>
      </Modal>
    )
  }

  /**
   * Show the modal to select a printer. Either does not show a modal if print has not been seleceted,
   * shows a message that no printers are available or shows a list of printers to select from
   *
   * @returns {JSX.Element}
   */
  selectPrinterModal = () => {
    if (this.print === null) {
      return (<></>)
    }
    if (this.print.printers.length === 0) {
      return (
        <>
          {t('printModal.noPrintersAvailable')}
          <IconButton name="refresh" onClick={async () => {
            this.print.printers = (await getPrinters()).filter((printer) => getPrinterType(printer) === this.print.printerType)
          }} />
        </>
      )
    }
    return (
      <PrintersList
        printers={this.print.printers}
        onSelect={(printer) => this.print.printer = printer}
      />
    )
  }
}
