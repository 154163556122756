import styled from 'styled-components'

export const PickLinesLinkContainer = styled.div`
margin-bottom: 20px;
`
export const IconButtonContainer = styled.div`
width:60px;
display:inline-block;
`

export const TextPickLinesHoverContainer = styled.div`
padding: 20px;
font-size: 30px;
text-align: center;
border-left: 4px solid rgba(137, 196, 244);
border-right: 4px solid rgba(137, 196, 244);
`

export const PickLinesAmountContainer = styled.div`
display:inline-block;
`

export const PickLinesHoverContainer = styled.div`
width:212px;
height:64px;
display: grid;
grid-template-columns: auto auto auto;
`