import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import { Form, Button, Popup } from 'semantic-ui-react'
import { TargetTextInput, TargetSelect } from 'spider/semantic-ui/Target'
import { ShippingUnitStore } from 'store/ShippingUnit'
import { IconButton } from 'spider/semantic-ui/Button'
import { TargetNumberInput } from '../../../../spider/semantic-ui/Target'
import styled from 'styled-components'
import { OutShipmentLineStore } from '../../../../store/OutShipmentLine'
import { omit } from 'lodash';
import { PickLinesLinkContainer, IconButtonContainer, TextPickLinesHoverContainer, PickLinesAmountContainer, PickLinesHoverContainer } from './helper'

const DeleteRowButtonContainer = styled.div`
    padding-top: ${({ i }) => i === 0 ? '28px' : '8px'}; 
    padding-bottom: 0;
`

@observer
export default class ColliTable extends Component {
    static propTypes = {
        colliTableId: PropTypes.number.isRequired,
        onChange: PropTypes.func.isRequired,
        isFrozenField: PropTypes.func.isRequired,
        shippingLabelCodes: PropTypes.object.isRequired,
        colloStartNr: PropTypes.number.isRequired,
        shippingUnits: PropTypes.instanceOf(ShippingUnitStore).isRequired,
        selectedPickLines: PropTypes.instanceOf(Set).isRequired,
        emptyPickLines: PropTypes.func.isRequired,
        pickLinesStore: PropTypes.instanceOf(OutShipmentLineStore).isRequired,
    }

    static maxNumberOfColli = 15;

    @observable numberOfColli = 1;

    getNumberOfCollie() {
        return this.numberOfColli;
    }

    updateNumberOfColli(newValue) {
        newValue = parseInt(newValue);
        if (newValue <= ColliTable.maxNumberOfColli && 0 <= newValue) {
            this.numberOfColli = newValue;
            this.forceUpdate();
            this.updateMainData();
        }
    }

    updateRefernece(reference, i) {
        this.references[i] = reference;
        this.updateMainData();
    }

    updateMainData() {
        const { onChange } = this.props;
        let packageData, newData;
        packageData = Array(this.numberOfColli);
        for (let i = 0; i < this.getNumberOfCollie(); i++) {
            packageData[i] = {
                'length': this.getGridData(i, 0),
                'width': this.getGridData(i, 1),
                'height': this.getGridData(i, 2),
                'weigth': this.getGridData(i, 3),
                'shippingMethod': this.getGridData(i,4),
                'amount': this.getGridData(i,5) ? this.getGridData(i,5) : 1,
                'reference': this.references[i],
                'pickLines': this.pickLines[i],
            }
        }
        newData = {
            'numberOfColli': this.getNumberOfCollie(),
            'packageData': packageData,
        };
        onChange(newData);
    }

    //Data of grid
    @observable gridData;
    @observable references = {};
    @observable pickLines = {};
    componentDidMount() {
        this.gridData = Array.from(Array(ColliTable.maxNumberOfColli), () => new Array(6));
        for (let i = 0; i < this.gridData.length; i++) {
            for (let j = 0; j < this.gridData[i].length; j++) {
                this.gridData[i][j] = 0;
            }
        }
        for (let i = 0; i < ColliTable.maxNumberOfColli; i++) {
            this.references[i] = '';
            this.pickLines[i] = {};
        }
        //this.updateMainData()

    }

    updateGridData(x, y, newValue) {
        const { shippingUnits } = this.props
        newValue = parseInt(newValue);
        if (isNaN(newValue)) {
            return
        }

        if (shippingUnits && shippingUnits.models && shippingUnits.models.length > 0 && !shippingUnits.models.some((unit) => unit.id === this.gridData[x][4])) {
            this.gridData[x][0] = shippingUnits.models[0].length;
            this.gridData[x][1] = shippingUnits.models[0].width;
            this.gridData[x][2] = shippingUnits.models[0].height;
            this.gridData[x][3] = shippingUnits.models[0].weight;
            this.gridData[x][4] = shippingUnits.models[0].id;
        }
        else if (((shippingUnits && shippingUnits.models && shippingUnits.models.length === 0) || (shippingUnits.length === 0)) && this.gridData[x][4] !== 0 ) {
            this.gridData[x][0] = 0;
            this.gridData[x][1] = 0;
            this.gridData[x][2] = 0;
            this.gridData[x][3] = 0;
            this.gridData[x][4] = 0;
        }

        this.gridData[x][y] = newValue;
        this.forceUpdate();
        this.updateMainData()
    }

    getGridData(x, y) {
        const { shippingUnits } = this.props
        if (this.gridData == null) {
            return 0;
        }

        if (shippingUnits && shippingUnits.models && shippingUnits.models.length > 0 && !shippingUnits.models.some((unit) => unit.id === this.gridData[x][4])) {
            switch(y){
                case 0:
                    return shippingUnits.models[0].length;
                case 1:
                    return shippingUnits.models[0].width;
                case 2:
                    return shippingUnits.models[0].height;
                case 3:
                    return shippingUnits.models[0].weight
                case 4:
                    return shippingUnits.models[0].id;
                default:
                    break;
            }
        }
        else if (((shippingUnits && shippingUnits.models && shippingUnits.models.length === 0) || (shippingUnits.length === 0)) && this.gridData[x][4] !== 0 && y<=4 ) {
            return 0
        }

        return this.gridData[x][y];
    }

    calculateRowVolume(i) {
        if (this.getGridData(i, 0) === 0 || this.getGridData(i, 1) === 0 || this.getGridData(i, 2) === 0) {
            return '-'
        }
        return (this.getGridData(i, 0) * this.getGridData(i, 1) * this.getGridData(i, 2)) / 1000000;
    }

    calculateTotalWeight() {
        let totalweight = 0;
        for (let i = 0; i < this.getNumberOfCollie(); i++) {
            if (this.getGridData(i, 3) === 0) {
                return '-'
            }
            totalweight += parseInt(this.getGridData(i, 3));
        }
        return totalweight;
    }

    getShippingMethods() {
        let shippingMethods = this.props.shippingUnits.map((item) => {
            return { name: item.description, ...item }
        })

        return shippingMethods ?? [];
    }

    updateValuesWithShippingMethod(i, shippingMethod) {
        this.updateGridData(i,0,shippingMethod.length)
        this.updateGridData(i,1,shippingMethod.width)
        this.updateGridData(i,2,shippingMethod.height)
        this.updateGridData(i,3,shippingMethod.weight)
        this.updateMainData();
    }

    removeRow(row){
        for (let i = row; i < ColliTable.maxNumberOfColli - 1; i++) {
            this.gridData[i] = this.gridData[i+1] 
        }
        this.gridData[ColliTable.maxNumberOfColli - 1] = new Array(6);
        this.updateMainData();
        this.numberOfColli--;
    }

    renderPicklines(i){
        const { pickLinesStore } = this.props

        if (this.pickLines == null || this.pickLines[i] === null) {
            return <></>
        }
      
        let pickLinesToRender = Object.keys(this.pickLines[i]).map((key, index) => ( 
            <Popup
                trigger={
                    <PickLinesAmountContainer
                        data-test-pick-order-line-amount-container={key}
                    >
                        {this.pickLines[i][key]}x 
                        {pickLinesStore.get(key).salesOrderLine.articleType.getLabel()}
                    </PickLinesAmountContainer>
                }
                flowing 
                hoverable
                on='click'
                pinned
            >
                <PickLinesHoverContainer>
                    <IconButtonContainer>
                        <IconButton
                            size='huge'
                            fluid
                            data-test-click-min-button
                            name="minus"
                            onClick={()=>{
                                this.pickLines[i][key]--
                                if(this.pickLines[i][key] <= 0){
                                    // Remove from this.picklines
                                    this.pickLines[i] = omit(this.pickLines[i], [key])
                                }
                                this.updateMainData();
                            }}
                        />
                    </IconButtonContainer>
                    <TextPickLinesHoverContainer>
                        {this.pickLines[i][key]}
                    </TextPickLinesHoverContainer>
                    <IconButtonContainer>
                        <IconButton
                            size='huge'
                            fluid
                            data-test-click-plus-button
                            name="plus"
                            onClick={()=>{  
                                let totalSelectedPickorderLine = 0
                                for (let j = 0; j < ColliTable.maxNumberOfColli; j++) {
                                    if(key in this.pickLines[j]){
                                        totalSelectedPickorderLine += this.pickLines[j][key]
                                    }
                                }
                                if(totalSelectedPickorderLine >= pickLinesStore.get(key).productionRequest.quantity.d[0]){
                                    return
                                }
                                this.pickLines[i][key]++
                                this.updateMainData();
                            }}
                        />
                    </IconButtonContainer>
                </PickLinesHoverContainer>
            </Popup>
        ))

        return (
            <>
                {pickLinesToRender}
            </>
        )
    }


    linkPickOrderLine(i){
        const { selectedPickLines, emptyPickLines } = this.props;
        // Add all selected pick lines
        // eslint-disable-next-line
        for (const pickLine of selectedPickLines) {
            if (pickLine in this.pickLines[i]) {
                this.pickLines[i][pickLine] = this.pickLines[i][pickLine] + 1;
            }
            else {
                this.pickLines[i][pickLine] = 1;
            }
        }   
        // clear the pick lines
        emptyPickLines();
        this.updateMainData();
    }

    render() {
        const { colliTableId, isFrozenField } = this.props;
        return (
            <>
                <Form
                    data-test-grid={colliTableId}
                    striped
                >
                    {[...Array(this.getNumberOfCollie())].map((x, i) => {
                        return (
                            <>
                                <Form.Group onClick={()=>{this.linkPickOrderLine(i)}} widths="equal">
                                    <TargetSelect data-test-unitTable-selector
                                        width={5}
                                        value={this.getGridData(i,4) ? this.getGridData(i,4) : null }
                                        label={i===0 ? t('workStation.production.performModal.carrierStep.pickShippingUnit') : null}
                                        disabled={isFrozenField()}
                                        placeholder={t('workStation.production.performModal.carrierStep.pickShippingUnit')}
                                        options={(this.getShippingMethods()).map((type) => ({
                                            value: type.id,
                                            text: type.name,
                                        }))}
                                        onChange={(newValue) => {
                                            this.updateGridData(i, 4, newValue);
                                            this.updateValuesWithShippingMethod(i,this.getShippingMethods().filter((x)=> x.id === newValue)[0])
                                        }}
                                    />
                                    <TargetNumberInput
                                        width={5}
                                        data-test-cel={[i, 5]}
                                        label={i===0 ? t('workStation.production.performModal.carrierStep.amount') : null}
                                        value={this.getGridData(i, 5) !== 0 ? this.getGridData(i, 5).toString() : '1'}
                                        onChange={(value) => { this.updateGridData(i, 5, parseInt(value)) }}
                                        disabled={isFrozenField()}
                                    />
                                    <TargetNumberInput
                                        data-test-cel={[i, 4]}
                                        width={5}
                                        label={i===0 ? t('workStation.production.performModal.carrierStep.weigth') : null}
                                        value={this.getGridData(i, 3) !== 0 ? this.getGridData(i, 3).toString() : ''}
                                        onChange={(value) => { this.updateGridData(i, 3, parseInt(value)) }}
                                        disabled={isFrozenField()}
                                        suffix='kg'
                                    />
                                    <TargetNumberInput
                                        data-test-cel={[i, 0]}
                                        width={5}
                                        label={i===0 ? t('workStation.production.performModal.carrierStep.length') : null}
                                        value={this.getGridData(i, 0) !== 0 ? this.getGridData(i, 0).toString() : ''}
                                        onChange={(value) => { this.updateGridData(i, 0, value) }}
                                        disabled={isFrozenField()}
                                        suffix='cm'
                                    />
                                    <TargetNumberInput
                                        data-test-cel={[i, 1]}
                                        width={5}
                                        label={i===0 ? t('workStation.production.performModal.carrierStep.width') : null}
                                        value={this.getGridData(i, 1) !== 0 ? this.getGridData(i, 1).toString() : ''}
                                        onChange={(value) => { this.updateGridData(i, 1, value) }}
                                        disabled={isFrozenField()}
                                        suffix='cm'
                                    />  
                                    <TargetNumberInput
                                        data-test-cel={[i, 2]}
                                        width={5}
                                        label={i===0 ? t('workStation.production.performModal.carrierStep.height') : null}
                                        value={this.getGridData(i, 2) !== 0 ? this.getGridData(i, 2).toString() : ''}
                                        onChange={(value) => { this.updateGridData(i, 2, value) }}
                                        disabled={isFrozenField()}
                                        suffix='cm'
                                    /> 
                                    <TargetTextInput 
                                        data-test-cel={[i, 3]}
                                        width={5}
                                        label={i===0 ? t('workStation.production.performModal.carrierStep.volume') : null}
                                        readOnly
                                        disabled={isFrozenField()}
                                        value={this.calculateRowVolume(i) + ' m3'}
                                        suffix='m3'
                                    />
                                    {/* <>{this.calculateRowVolume(i) + 'm3'}</> */}
                                    <TargetTextInput
                                        label={i===0 ? t('workStation.production.performModal.carrierStep.reference') : null}
                                        value={this.references[i]}
                                        onChange={(val) => {
                                            this.updateRefernece(val, i);
                                        }}
                                        disabled={isFrozenField()}
                                    />
                                    <DeleteRowButtonContainer
                                        i={i}
                                    >
                                        <Button
                                            onClick={() => {this.removeRow(i)}}
                                            icon='delete'
                                            size='large'
                                            label={null}
                                        />
                                    </DeleteRowButtonContainer>
                                </Form.Group>
                                <PickLinesLinkContainer>
                                    <IconButton
                                        data-test-link-pick-lines={i}
                                        name="chain"
                                        onClick={()=>{this.linkPickOrderLine(i)}}
                                    />
                                    {this.pickLines[i] && Object.keys(this.pickLines[i]).length === 0 && (
                                        <>{t('workStation.production.performModal.carrierStep.link')}</>
                                    )}
                                    {this.pickLines[i] && Object.keys(this.pickLines[i]).length > 0 && this.renderPicklines(i)}
                                </PickLinesLinkContainer>
                            </>
                        )
                    })}
                </Form>
                <Button
                    data-test-add-shipment-line
                    primary
                    size='huge'
                    icon="plus"
                    labelPosition="left"
                    content="Add shipment line"
                    disabled={this.numberOfColli >= ColliTable.maxNumberOfColli}
                    onClick={()=>{
                        if(this.numberOfColli < ColliTable.maxNumberOfColli){
                            this.numberOfColli++;
                        }
                    }}
                />
            </>
        )
    }
}