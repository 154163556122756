import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { observable, computed, reaction, action, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import styled from 'styled-components'
import moment from 'moment'
import { Form, Segment, Icon, Dropdown, Popup, Button, Label, Table } from 'semantic-ui-react'
import ScrollModal from 'spider/semantic-ui/ScrollModal'
import { tint } from 'polished'
import { User } from 'store/User'
import { Operator } from 'store/Operator'
import { WorkSlot } from 'store/WorkSlot'
import { WorkTimeStore } from 'store/WorkTime'
import { LeaveSlot, STATUS_ICONS as LEAVE_SLOT_STATUS_ICONS } from 'store/LeaveSlot'
import { LeaveCalendar } from 'store/LeaveCalendar'
import {
  TargetCheckbox,
  TargetTextInput,
  TargetRadioButtons,
  TargetDateTimePicker,
  TargetTimePicker,
  TargetDatePicker,
} from 'spider/semantic-ui/Target'
import { IconButton } from 'spider/semantic-ui/Button'
import { theme } from 'styles'
import { t } from 'i18n'
import Scrollbars from 'react-custom-scrollbars'
import LeaveSlotViewComments from 'container/LeaveSlot/View/Comments'
import { SERVER_DATETIME_FORMAT, TIME_FORMAT, DATE_FORMAT, formatDuration, snakeToCamel } from 'helpers'
import { showSaveNotification, showNotification } from 'helpers/notification'
import MarkingNegative from 'image/marking_negative.svg'
import MarkingPositive from 'image/marking_positive.svg'
import RightDivider from 'spider/component/RightDivider'

const WORK_SLOT_COLOR = theme.tintedPrimaryColor
const LEAVE_SLOT_COLOR = tint(0.15, '#DB2828')
const OVERTIME_COLOR = '#F0F0F0'

const CalendarContainer = styled(Segment.Group)`
  margin: 0 0 0.25rem !important;
`

const CalendarHeader = styled(Segment)`
  position: relative;
  text-align: center;
  font-size: 1.25rem !important;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.6) !important;
  padding: 0.75rem !important;
`

const CalendarHeaderButton = styled(Icon)`
  position: absolute;
  top: 0.5rem;
  ${({ side }) => side}: 0.5rem;
  width: 1.75rem !important;
  height: 1.75rem !important;
  line-height: 1.75rem;
  margin: 0 !important;

  cursor: pointer;
  color: rgba(0, 0, 0, 0.3) !important;
  &:hover {
    color: rgba(0, 0, 0, 0.6) !important;
  }

  transition: color 300ms ease;
`

const CalendarBody = styled(Segment)`
  position: relative;
  background-color: #f0f0f0 !important;
  height: 20rem;
`

const CalendarDays = styled.div`
  display: flex;
  margin: 0.75rem -0.75rem -0.75rem;
`

const CalendarDay = styled.div`
  flex: 1 1 0;
  text-align: center;
  heigth: 1.25rem;
  line-height: 1.25rem;
  font-size: 0.75rem;
  color: rgba(0, 0, 0, 0.3);
  border-right: 1px solid rgba(34, 36, 38, 0.1);
  &:last-child {
    border-right: none;
  }
`

const AutomaticClockOut = styled.div`
  color: #db2828;
  font-weight: bold;
  font-size: 0.6em;
  position: absolute;
  width: 100%;
  left: 50%;
  bottom: 0.5em;
  transform: translate(-50%);
`;

const CalendarBlock = styled.div`
  position: absolute;
  left: calc(${({ day, days }) => day * (100 / days)}% + 0.25rem);
  top: calc(
    ${({ time, wrapTop }) => {
      time /= 24 * 60 * 60
      return `calc(${100 * time}% + ${(wrapTop ? 0 : 0.25) - 0.5 * time}rem)`
    }}
  );
  width: calc(${({ width = 1, days }) => width * (100 / days)}% - 0.5rem);
  height: calc(
    ${({ duration, wrapTop, wrapBottom }) => {
      duration /= 24 * 60 * 60
      return `calc(${100 * duration}% + ${(wrapTop ? 0.25 : 0) + (wrapBottom ? 0.25 : 0) - 0.5 * duration}rem)`
    }}
  );

  ${({ overtime, color }) =>
    overtime
      ? `
        background-image: url(${MarkingPositive});
        background-position: top left;
    `
      : `
        background-color: ${color};
        border: 1px solid rgba(34, 36, 38, 0.15);
    `}
  border-radius: 0.25rem;

  ${({ wrapTop }) =>
    wrapTop
      ? `
        border-top: none;
    `
      : ``}
  ${({ wrapTop, flatTop }) =>
    wrapTop || flatTop
      ? `
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    `
      : ``}
    ${({ wrapBottom }) =>
    wrapBottom
      ? `
        border-bottom: none;
    `
      : ``}
    ${({ wrapBottom, flatBottom }) =>
    wrapBottom || flatBottom
      ? `
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    `
      : ``}

    font-size: 0.9rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: rgba(0, 0, 0, 0.5);
  overflow: hidden;

  ${({ clickable }) =>
    clickable
      ? `
        cursor: pointer;
    `
      : ``}

  ${({ automaticallyClockedOut }) => automaticallyClockedOut && `
    border-bottom: 2px solid #db2828;
  `}
`

const CalendarBlockDescription = styled.div`
  text-align: center;
  line-height: 1.15;
  z-index: 2;
  text-shadow: 0 0 5px ${({ color }) => color};
`

const CalendarBlockOverlay = styled.div`
  position: absolute;
  left: 0;
  z-index: 1;
  top: ${({ start, wrapTop, wrapBottom }) =>
    `calc(${start * 100}% + ${
      (wrapTop ? 0.25 : 0) -
      ((wrapTop ? 0.25 : 0) + (wrapBottom ? 0.25 : 0)) * start -
      (wrapTop && start === 0 ? 0.25 : 0)
    }rem)`};
  width: 100%;
  height: ${({ start, end, wrapTop, wrapBottom }) =>
    `calc(${(end - start) * 100}% + ${
      ((wrapTop ? 0.25 : 0) + (wrapBottom ? 0.25 : 0)) * start +
      (wrapTop && start === 0 ? 0.25 : 0) +
      (wrapBottom && end === 1 ? 0.25 : 0)
    }rem)`};
  background-image: url(${MarkingNegative});
  background-position: top left;
`

const CalendarDrag = styled.div`
  position: absolute;
  left: calc(${({ day, days }) => day * (100 / days)}% + 0.25rem);
  top: 0.25rem;
  width: calc(${({ width = 1, days }) => width * (100 / days)}% - 0.5rem);
  height: calc(100% - 0.5rem);
  border-radius: 0.25rem;

  background-color: #e0e0e0;
  transition: left 300ms ease, width 300ms ease;
`

const ScopeContainer = styled.div`
  display: flex;
  color: rgba(0, 0, 0, 0.45);
  align-items: center;
`

const TotalTime = styled.span`
  font-weight: bold;
  margin-right: 0.75em;
`

const TotalOvertime = styled(TotalTime)`
  color: #21ba45;
`

const TotalAbsence = styled(TotalTime)`
  color: #db2828;
`

const WorkTimesButton = styled(IconButton)`
  font-size: 0.9em !important;
  margin: 0 !important;
`

const CalendarPopup = styled(Popup)`
  text-align: center;
  padding: 0.5rem !important;
`

const CornerIcon = styled(Icon)`
  position: absolute;
  top: 0.25rem;
  right: 0.25rem;

  cursor: pointer;
  opacity: 0.5 !important;
  &:hover {
    opacity: 0.75 !important;
  }
  transition: opacity 300ms ease;
`

const CornerSpan = styled.span`
  position: absolute;
  top: 0.25rem;
  left: 0.25rem;
`

const LeaveSlotDescription = styled.div`
  text-align: center;
  ${({ popup }) =>
    popup
      ? `
        padding-top: 2em;
    `
      : ``}
`

const StatusLabel = styled(Label)`
  > i.icon {
    margin: 0 !important;
  }
  padding: 0.5833em !important;
`

const CommentsIcon = styled(Icon)`
  margin: 0 0 0 0.25rem !important;
  color: rgba(0, 0, 0, 0.33) !important;
`

const LeaveSlotModal = styled(ScrollModal)`
  max-height: 45em !important;
`

const LeaveSlotModalContent = styled(ScrollModal.Content)`
  > div > div:first-child > div {
    padding: 0;
    display: flex;
    align-items: stretch;
    height: 100%;
  }
`

const LeaveSlotModalInfo = styled(Scrollbars)`
  flex: 1 1 auto;
  > div:first-child {
    padding: 1.5em;
  }
  border-right: 1px solid rgba(0, 0, 0, 0.15);
  margin-right: 0.75em;
`

const MONTHS = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
]

const SCOPE_OPTIONS = [
  { value: 'week', text: t('workCalendar.scope.type.week.label') },
  { value: 'month', text: t('workCalendar.scope.type.month.label') },
]

const SCOPE_DATE_INCLUDE_WEEKDAY = {
  week: true,
  month: false,
}
const SCOPE_DATE_FORMATS = {
  week: 'DD-MM',
  month: 'DD',
}

function dateRange(start, end) {
  let dates = []
  for (let date = start.clone(); date.isBefore(end); date = date.clone().add(1, 'd')) {
    dates.push(date)
  }
  return dates
}

function isoWeeks(year) {
  // 28th of december always falls in the last iso week of the year
  return moment({ year, month: 11, day: 28 }).isoWeek()
}

function changeOffset(m, offset) {
  return m
    .clone()
    .utcOffset(offset)
    .add(m.utcOffset() - offset, 'm')
}

function normalizeTime(time) {
  return time.hour() * 60 * 60 + time.minute() * 60 + time.second()
}

@observer
class LeaveSlotWindow extends Component {
  static propTypes = {
    leaveSlot: PropTypes.instanceOf(LeaveSlot).isRequired,
  }

  @observable fullDay = true

  constructor(...args) {
    super(...args)

    this.changeFullDay = this.changeFullDay.bind(this)
    this.inferFullDay = this.inferFullDay.bind(this)

    this.inferFullDay()
  }

  componentDidMount() {
    this.leaveSlotReaction = reaction(() => this.props.leaveSlot.id, this.inferFullDay)
  }

  componentWillUnmount() {
    this.leaveSlotReaction()
  }

  inferFullDay() {
    const { leaveSlot } = this.props
    this.fullDay =
      leaveSlot.startDate.isSame(leaveSlot.startDate.clone().startOf('day')) &&
      leaveSlot.endDate.isSame(leaveSlot.endDate.clone().endOf('day'))
  }

  @action changeFullDay(fullDay) {
    const { leaveSlot } = this.props

    this.fullDay = fullDay

    if (fullDay) {
      leaveSlot.setInput('startDate', leaveSlot.startDate.clone().startOf('day'))
      leaveSlot.setInput('endDate', leaveSlot.endDate.clone().endOf('day'))
    }
  }

  render() {
    const { leaveSlot } = this.props

    return (
      <>
        <TargetCheckbox
          noLabel
          rightLabel
          label={t('leaveSlot.field.fullDay.label')}
          name="fullDay"
          value={this.fullDay}
          onChange={this.changeFullDay}
        />
        {this.fullDay ? (
          <>
            <TargetDatePicker target={leaveSlot} name="startDate" toTarget={(dateTime) => dateTime.startOf('day')} />
            <TargetDatePicker target={leaveSlot} name="endDate" toTarget={(dateTime) => dateTime.endOf('day')} />
          </>
        ) : (
          <>
            <TargetDateTimePicker target={leaveSlot} name="startDate" />
            <TargetDateTimePicker target={leaveSlot} name="endDate" />
          </>
        )}
      </>
    )
  }
}

@observer
export default class WorkCalendar extends Component {
  static propTypes = {
    user: PropTypes.instanceOf(User),
    operator: PropTypes.instanceOf(Operator),
    leaveCalendar: PropTypes.instanceOf(LeaveCalendar),
  }

  constructor(...args) {
    super(...args)
    this.renderDay = this.renderDay.bind(this)
    this.onScopeChange = this.onScopeChange.bind(this)
    this.setPrevScope = this.setPrevScope.bind(this)
    this.setNextScope = this.setNextScope.bind(this)
    this.renderWorkSlot = this.renderWorkSlot.bind(this)
    this.renderLeaveSlot = this.renderLeaveSlot.bind(this)
    this.renderLeaveCalendar = this.renderLeaveCalendar.bind(this)
    this.onCalendarMouseDown = this.onCalendarMouseDown.bind(this)
    this.onCalendarMouseMove = this.onCalendarMouseMove.bind(this)
    this.onCalendarMouseUp = this.onCalendarMouseUp.bind(this)
    this.onMouseUp = this.onMouseUp.bind(this)
    this.onLeaveSlotSubmit = this.onLeaveSlotSubmit.bind(this)
    this.onLeaveSlotCancel = this.onLeaveSlotCancel.bind(this)
  }

  componentDidMount() {
    document.addEventListener('mouseup', this.onMouseUp)
    this.scopeReaction = reaction(
      () => [this.props.user, this.props.operator, this.scopeStart, this.scopeEnd],
      action(([user, operator, start, end]) => {
        this.workTimes = null

        if (user || operator) {
          const time = moment()
          if (time.isAfter(start)) {
            this.workTimes = {
              time,
              store: new WorkTimeStore({
                limit: false,
                params: {
                  '.end_time:gte_or_isnull': start.format(SERVER_DATETIME_FORMAT),
                  '.start_time:lte': end.format(SERVER_DATETIME_FORMAT),
                  ...(user ? { '.user': user.id } : {}),
                  ...(operator ? { '.operator': operator.id } : {}),
                },
              }),
            }
            this.workTimes.store.fetch()
          }
        }
      }),
      { fireImmediately: true }
    )
  }

  componentWillUnmount() {
    document.removeEventListener('mouseup', this.onMouseUp)
    this.scopeReaction()
  }

  @observable scope = {
    type: 'week',
    year: moment().isoWeekYear(),
    week: moment().isoWeek(),
  }
  @observable workTimes = null

  onScopeChange(e, { value }) {
    switch (value) {
      case 'week':
        this.scope = {
          type: 'week',
          year: this.scopeStart.isoWeekYear(),
          week: this.scopeStart.isoWeek(),
        }
        break
      case 'month':
        this.scope = {
          type: 'month',
          year: this.scopeStart.year(),
          month: this.scopeStart.month(),
        }
        break
      default:
        throw new Error(`unknown scope type: ${this.scope.type}`)
    }
  }

  setPrevScope() {
    switch (this.scope.type) {
      case 'week':
        if (this.scope.week === 1) {
          this.scope = {
            type: 'week',
            year: this.scope.year - 1,
            week: isoWeeks(this.scope.year - 1),
          }
        } else {
          this.scope = {
            type: 'week',
            year: this.scope.year,
            week: this.scope.week - 1,
          }
        }
        break
      case 'month':
        if (this.scope.month === 0) {
          this.scope = {
            type: 'month',
            year: this.scope.year - 1,
            month: 11,
          }
        } else {
          this.scope = {
            type: 'month',
            year: this.scope.year,
            month: this.scope.month - 1,
          }
        }
        break
      default:
        throw new Error(`unknown scope type: ${this.scope.type}`)
    }
  }

  setNextScope() {
    switch (this.scope.type) {
      case 'week':
        if (this.scope.week === isoWeeks(this.scope.year)) {
          this.scope = {
            type: 'week',
            year: this.scope.year + 1,
            week: 1,
          }
        } else {
          this.scope = {
            type: 'week',
            year: this.scope.year,
            week: this.scope.week + 1,
          }
        }
        break
      case 'month':
        if (this.scope.month === 11) {
          this.scope = {
            type: 'month',
            year: this.scope.year + 1,
            month: 0,
          }
        } else {
          this.scope = {
            type: 'month',
            year: this.scope.year,
            month: this.scope.month + 1,
          }
        }
        break
      default:
        throw new Error(`unknown scope type: ${this.scope.type}`)
    }
  }

  @computed get scopeStart() {
    switch (this.scope.type) {
      case 'week':
        return moment().isoWeekYear(this.scope.year).isoWeekday(1).isoWeek(this.scope.week).startOf('day')
      case 'month':
        return moment({
          year: this.scope.year,
          month: this.scope.month,
          day: 1,
        }).startOf('day')
      default:
        throw new Error(`unknown scope type: ${this.scope.type}`)
    }
  }

  @computed get scopeEnd() {
    switch (this.scope.type) {
      case 'week':
        return this.scopeStart.clone().endOf('isoWeek')
      case 'month':
        return this.scopeStart.clone().endOf('month')
      default:
        throw new Error(`unknown scope type: ${this.scope.type}`)
    }
  }

  @computed get leaveSlotFilter() {
    return (leaveSlot) =>
      leaveSlot.startDate.clone().startOf('day').isBefore(this.scopeEnd) &&
      leaveSlot.endDate.clone().endOf('day').isAfter(this.scopeStart)
  }

  @computed get scopedLeaveSlots() {
    const { user, operator, leaveCalendar } = this.props
    const target = user || operator || leaveCalendar
    const leaveSlots = target && target.leaveSlots

    if (!leaveSlots) {
      return []
    }
    return leaveSlots.filter(this.leaveSlotFilter)
  }

  @computed get scopedLeaveCalendars() {
    const { user, operator } = this.props
    const target = user || operator
    const leaveCalendars = target && target.leaveCalendars

    if (!leaveCalendars) {
      return []
    }
    const scopedLeaveCalendars = []
    // eslint-disable-next-line
    for (const leaveCalendar of leaveCalendars.models) {
      const leaveSlots = leaveCalendar.leaveSlots.filter(this.leaveSlotFilter)
      if (leaveSlots.length > 0) {
        scopedLeaveCalendars.push({ leaveCalendar, leaveSlots })
      }
    }
    return scopedLeaveCalendars
  }

  @computed get flatLeaveSlots() {
    const flatLeaveSlots = []
    flatLeaveSlots.push(...this.scopedLeaveSlots)
    // eslint-disable-next-line
    for (const { leaveSlots } of this.scopedLeaveCalendars) {
      flatLeaveSlots.push(...leaveSlots)
    }
    return flatLeaveSlots
  }

  @computed get scopedWorkSlots() {
    const { user, operator } = this.props

    const target = user || operator
    let workSchedules = target && target.workSchedules

    if (!workSchedules || workSchedules.length === 0) {
      return []
    }

    workSchedules = workSchedules.models
      .slice()
      .sort((l, r) => (l.startDate.isBefore(r.startDate) ? -1 : l.startDate.isAfter(r.startDate) ? 1 : 0))
    let workSchedule = 0

    const scopedWorkSlots = []
    for (let day = this.scopeStart.clone(); day.isBefore(this.scopeEnd); day.add(1, 'd')) {
      while (workSchedule < workSchedules.length - 1 && day.isSameOrAfter(workSchedules[workSchedule + 1].startDate)) {
        workSchedule++
      }
      // eslint-disable-next-line
      for (const workSlot of workSchedules[workSchedule].workSlots.models) {
        if (workSlot[WorkSlot.DAYS[day.isoWeekday() - 1]]) {
          const start = day
            .clone()
            .hour(workSlot.startTime.hour())
            .minute(workSlot.startTime.minute())
            .second(workSlot.startTime.second())
          const end = day
            .clone()
            .hour(workSlot.endTime.hour())
            .minute(workSlot.endTime.minute())
            .second(workSlot.endTime.second())
          scopedWorkSlots.push({ start, end })
        }
      }
    }
    return scopedWorkSlots
  }

  @computed
  get scopedWorkedSlots() {
    // So to combine work slots and work times we first turn it into one
    // big array of events
    const events = []
    //eslint-disable-next-line
    for (const { start, end } of this.scopedWorkSlots) {
      events.push({
        type: 'workSlotStart',
        time: start,
      })
      events.push({
        type: 'workSlotEnd',
        time: end,
      })
      // If work times are not loaded we assume perfect attendance
      if (this.workTimes === null || this.workTimes.store.isLoading) {
        events.push({
          type: 'workTimeStart',
          time: start,
        })
        events.push({
          type: 'workTimeEnd',
          time: end,
          automaticallyClockedOut: false,
        })
      }
    }
    // eslint-disable-next-line
    for (const { startDate, endDate } of this.flatLeaveSlots) {
      events.push({
        type: 'leaveSlotStart',
        time: startDate,
      })
      events.push({
        type: 'leaveSlotEnd',
        time: endDate,
      })
    }

    if (this.workTimes !== null) {
      if (!this.workTimes.store.isLoading) {
        // eslint-disable-next-line
        for (let { startTime, endTime, automaticallyClockedOut } of this.workTimes.store.models) {
          // Normalize offset
          startTime = changeOffset(startTime, this.scopeStart.utcOffset())
          endTime = endTime && changeOffset(endTime, this.scopeStart.utcOffset())
          // Fit within scope
          startTime = startTime.isBefore(this.scopeStart) ? this.scopeStart : startTime
          endTime = endTime && (endTime.isAfter(this.scopeEnd) ? this.scopeEnd : endTime)
          // Add events
          events.push({ type: 'workTimeStart', time: startTime })
          if (endTime) {
            events.push({ type: 'workTimeEnd', time: endTime, automaticallyClockedOut })
          }
        }
      }
      if (this.workTimes.time.isBefore(this.scopeEnd)) {
        events.push({
          type: 'currentTime',
          time: this.workTimes.time,
        })
      }
    }

    // We can now sort these events so that we can easily process them
    events.sort((l, r) => l.time.diff(r.time))

    // Now we create a list of slots based on these events
    const slots = []
    let slot = null

    let future = false
    let working = 0
    let planned = 0
    let leave = 0
    let automaticClockOut = null

    // eslint-disable-next-line
    for (const { type, time, automaticallyClockedOut = false } of events) {
      switch (type) {
        case 'currentTime':
          future = true
          break
        case 'workSlotStart':
          planned++
          break
        case 'workSlotEnd':
          planned--
          break
        case 'leaveSlotStart':
          leave++
          break
        case 'leaveSlotEnd':
          leave--
          break
        case 'workTimeStart':
          working++
          break
        case 'workTimeEnd':
          working--
          break
        default:
          console.warn('Unexpected event type:', type)
      }

      if (automaticallyClockedOut) {
        automaticClockOut = time
      } else if (automaticClockOut && !automaticClockOut.isSame(time)) {
        automaticClockOut = null
      }

      let state = null
      if        (leave <= 0 && planned > 0 && (working > 0 || future)) {
        state = 'planned'
      } else if (leave <= 0 && planned > 0 && !(working > 0 || future)) {
        state = 'absent'
      } else if (leave <= 0 && working > 0 && !future) {
        state = 'overtime'
      }

      if (slot && slot.state !== state) {
        if (!time.isSame(slot.start)) {
          slots.push({ ...slot, end: time, automaticallyClockedOut: automaticClockOut !== null })
        } else if (automaticallyClockedOut && slots.length > 0 && slots[slots.length - 1].end.isSame(time)) {
          slots[slots.length - 1].automaticallyClockedOut = true
        }
        slot = null
      }
      if (!slot && state) {
        slot = { state, start: time }
      }
    }

    if (slot && !this.scopeEnd.isSame(slot.start)) {
      slots.push({ ...slot, end: this.scopeEnd })
    }

    // Now we seperate these slots based on days
    const daySlots = []
    // eslint-disable-next-line
    for (let { state, start, end, automaticallyClockedOut } of slots) {
      let wrapTop = false
      while (true) {
        const endOfDay = start.clone().endOf('day')
        if (endOfDay.isBefore(end)) {
          daySlots.push({
            state,
            start,
            end: endOfDay,
            wrapTop,
            wrapBottom: true,
            automaticallyClockedOut: false,
          })

          wrapTop = true
          start = start.clone().startOf('day').add(1, 'day')
        } else {
          daySlots.push({
            state,
            start,
            end,
            wrapTop,
            wrapBottom: false,
            automaticallyClockedOut,
          })
          break
        }
      }
    }

    // Now we group absent slots into planned slots
    const groupedSlots = []
    // eslint-disable-next-line
    for (const slot of daySlots) {
      const groupState = slot.state === 'absent' ? 'planned' : slot.state

      if (
        groupedSlots.length > 0 &&
        groupedSlots[groupedSlots.length - 1].end.isSame(slot.start) &&
        groupedSlots[groupedSlots.length - 1].state === groupState
      ) {
        groupedSlots[groupedSlots.length - 1].end = slot.end
        groupedSlots[groupedSlots.length - 1].wrapBottom = slot.wrapBottom
      } else {
        const group = {
          ...slot,
          state: groupState,
          overlays: [],
          flatTop: false,
          flatBottom: false,
        }
        if (groupedSlots.length > 0 && groupedSlots[groupedSlots.length - 1].end.isSame(group.start)) {
          groupedSlots[groupedSlots.length - 1].flatBottom = true
          group.flatTop = true
        }
        groupedSlots.push(group)
      }

      const group = groupedSlots[groupedSlots.length - 1]
      if (slot.state !== groupState) {
        group.overlays.push({ state: slot.state, start: slot.start, end: slot.end })
      }
    }

    // Now we normalize start and end of overlays into numbers from 0 to 1
    // eslint-disable-next-line
    for (const { start, end, overlays } of groupedSlots) {
      if (overlays.length > 0) {
        const duration = end.diff(start)
        // eslint-disable-next-line
        for (const overlay of overlays) {
          overlay.startPos = overlay.start.diff(start) / duration
          overlay.endPos = overlay.end.diff(start) / duration
        }
      }
    }

    return groupedSlots
  }

  @computed get scopedWorkedSlotsUntilTime() {
    const slots = []
    // eslint-disable-next-line
    for (const slot of this.scopedWorkedSlots) {
      if (slot.start.isSameOrAfter(this.workTimes.time)) {
        // Slot is after current time
        break // Slots are in order so we can stop
      }
      if (slot.end.isSameOrBefore(this.workTimes.time)) {
        // Slot is fully contained
        slots.push(slot)
        continue
      }
      const newSlot = { ...slot, end: this.workTimes.time }
      if (slot.overlays) {
        newSlot.overlays = []
        // eslint-disable-next-line
        for (const overlay of slot.overlays) {
          if (overlay.start.isSameOrAfter(this.workTimes.time)) {
            // Overlay is after current time
            break // Overlays are in order so we can stop
          }
          if (overlay.end.isSameOrBefore(this.workTimes.time)) {
            // Overlay is fully contained
            newSlot.overlays.push(overlay)
            continue
          }
          newSlot.overlays.push({ ...overlay, end: this.workTimes.time })
        }
      }
      slots.push(newSlot)
    }
    return slots
  }

  @computed get totalTimePlanned() {
    let totalTimePlanned = 0

    // eslint-disable-next-line
    for (const { state, start, end } of this.scopedWorkedSlotsUntilTime) {
      if (state === 'planned') {
        totalTimePlanned += end.diff(start, 'm', true)
      }
    }

    return Math.round(totalTimePlanned)
  }

  @computed get totalTimeActual() {
    let totalTimeActual = 0

    // eslint-disable-next-line
    for (const { state, start, end, overlays } of this.scopedWorkedSlotsUntilTime) {
      totalTimeActual += end.diff(start, 'm', true)
      if (state === 'planned') {
        // eslint-disable-next-line
        for (const { state, start, end } of overlays) {
          if (state === 'absent') {
            totalTimeActual -= end.diff(start, 'm', true)
          }
        }
      }
    }

    return Math.round(totalTimeActual)
  }

  renderDay(date, i) {
    return (
      <CalendarDay key={i}>
        {SCOPE_DATE_INCLUDE_WEEKDAY[this.scope.type] &&
          `${t(`daycy.weekDay.${WorkSlot.DAYS[date.isoWeekday() - 1]}`)} `}
        {date.format(SCOPE_DATE_FORMATS[this.scope.type])}
      </CalendarDay>
    )
  }

  @computed get days() {
    return this.scopeEnd.diff(this.scopeStart, 'd') + 1
  }

  @computed get popup() {
    return this.days > 7
  }

  renderOverlay({ state, start, end, startPos, endPos }, i) {
    return (
      <CalendarPopup
        hoverable
        position="top center"
        trigger={<CalendarBlockOverlay key={i} start={startPos} end={endPos} />}
        content={
          <>
            <div>
              <b>{t(`workCalendar.slot.${state}`)}</b>
            </div>
            <div>
              {start.format('HH:mm')} - {end.format('HH:mm')}
            </div>
          </>
        }
      />
    )
  }

  renderBlock({ description, color, startTime, endTime, overlays = [], automaticallyClockedOut, ...props }) {
    const time = startTime
    const duration = endTime - startTime

    const popup = this.popup || duration < 3 * 60 * 60

    let block = (
      <CalendarBlock
        data-test-block-part={true}
        days={this.days}
        time={time}
        duration={duration}
        color={color}
        automaticallyClockedOut={automaticallyClockedOut}
        {...props}
      >
        {!popup && <CalendarBlockDescription color={color}>{description}</CalendarBlockDescription>}
        {overlays
          .map((overlay) => ({
            ...overlay,
            wrapTop: props.wrapTop,
            wrapBottom: props.wrapBottom,
          }))
          .map(this.renderOverlay)}
      </CalendarBlock>
    )

    if (popup) {
      block = <CalendarPopup hoverable position="top center" content={description} trigger={block} />
    }

    return block
  }

  renderWorkSlot({ state, start, end, overlays, flatTop, flatBottom, wrapTop, wrapBottom, automaticallyClockedOut }, i) {
    const day = start.diff(this.scopeStart, 'd')
    const startTime = normalizeTime(start)
    const endTime = normalizeTime(end)

    return (
      <React.Fragment key={i}>
        {this.renderBlock({
          day,
          startTime,
          endTime,
          flatTop,
          flatBottom,
          wrapTop,
          wrapBottom,
          overlays,
          automaticallyClockedOut,
          color: state === 'overtime' ? OVERTIME_COLOR : WORK_SLOT_COLOR,
          overtime: state === 'overtime',
          description: (
            <>
              <div>
                <b>{t(`workCalendar.slot.${state}`)}</b>
              </div>
              <div>
                {start.format('HH:mm')} - {end.format('HH:mm')}
              </div>
              {automaticallyClockedOut && (
                <AutomaticClockOut data-test-automatic-clock-out>
                  <Icon name="warning sign" />
                  {t('workTime.field.automaticallyClockedOut.label')}
                </AutomaticClockOut>
              )}
            </>
          ),
        })}
      </React.Fragment>
    )
  }

  renderLeaveSlot(leaveSlot, _, __, leaveCalendar) {
    const { user, operator } = this.props

    const leaveSlots = (user || operator || this.props.leaveCalendar).leaveSlots

    const startDay = leaveSlot.startDate.diff(this.scopeStart, 'd')
    const endDay = leaveSlot.endDate.diff(this.scopeStart, 'd')
    const days = []
    for (let day = startDay; day <= endDay; day++) {
      days.push({
        day,
        startTime: day === startDay ? normalizeTime(leaveSlot.startDate) : 0,
        endTime: day === endDay ? normalizeTime(leaveSlot.endDate) : 24 * 60 * 60,
        wrapTop: day !== startDay,
        wrapBottom: day !== endDay,
      })
    }

    return (
      <React.Fragment key={leaveSlot.cid}>
        {days.map((day) =>
          this.renderBlock({
            ...day,
            key: day.day,
            color: LEAVE_SLOT_COLOR,
            description: (
              <LeaveSlotDescription popup={this.popup}>
                <div>
                  <b>
                    {leaveSlot.type && `${t(`leaveSlot.field.type.valueShort.${leaveSlot.type}`)}: `}
                    {leaveSlot.name}
                  </b>
                </div>
                <div>
                  {leaveSlot.startDate.format('DD-MM-YYYY')}
                  {startDay !== endDay && ` - ${leaveSlot.endDate.format('DD-MM-YYYY')}`}
                </div>
                <CornerSpan>
                  <Popup
                    trigger={
                      <StatusLabel
                        data-test-leave-slot-status={leaveSlot.status}
                        color={
                          {
                            pending: 'yellow',
                            approved: 'green',
                            rejected: 'red',
                          }[leaveSlot.status]
                        }
                        icon={LEAVE_SLOT_STATUS_ICONS[leaveSlot.status]}
                      />
                    }
                    content={t(`leaveSlot.field.status.value.${leaveSlot.status}`)}
                  />
                  {leaveSlot.comments && leaveSlot.comments.length > 0 && (
                    <Popup trigger={<CommentsIcon name="chat" />} content={t('leaveSlot.overview.hasComments')} />
                  )}
                </CornerSpan>
                {leaveCalendar && (
                  <div>
                    <i>({leaveCalendar.name})</i>
                  </div>
                )}
                {!leaveCalendar && (
                  <CornerIcon
                    className="clickable"
                    data-test-delete-leave-slot
                    name="trash alternate"
                    onClick={(e) => {
                      e.stopPropagation()
                      leaveSlots.ignoreSetChanges(async () => {
                        await leaveSlot.delete()
                        leaveSlots.remove(leaveSlot)
                        showSaveNotification()
                      })
                    }}
                  />
                )}
              </LeaveSlotDescription>
            ),
            clickable: true,
            onClick:
              user || operator
                ? () => (this.leaveSlot = new LeaveSlot(leaveSlot.toJS(), { relations: leaveSlot.__activeRelations }))
                : undefined,
            onMouseDown: (e) => {
              if (e.button === 0) {
                e.stopPropagation()
              }
            },
          })
        )}
      </React.Fragment>
    )
  }

  renderLeaveCalendar({ leaveCalendar, leaveSlots }) {
    return (
      <React.Fragment key={leaveCalendar.cid}>
        {leaveSlots.map((leaveSlot, i, leaveSlots) => this.renderLeaveSlot(leaveSlot, i, leaveSlots, leaveCalendar))}
      </React.Fragment>
    )
  }

  @observable drag = null
  @observable leaveSlot = null

  @computed get absoluteDrag() {
    if (this.drag === null) {
      return null
    }

    if (this.drag.end.isBefore(this.drag.start)) {
      return { ...this.drag, start: this.drag.end, end: this.drag.start }
    } else {
      return this.drag
    }
  }

  getDateFromMouseEvent(e) {
    let target = e.target
    while (!target.classList.contains('calendar-body')) {
      target = target.parentElement
    }
    const { x, width } = target.getBoundingClientRect()
    const offset = Math.floor(((e.clientX - x) / width) * this.days)
    return this.scopeStart.clone().add(offset, 'd')
  }

  onCalendarMouseDown(e) {
    if (e.button === 0) {
      let target = e.target
      while (!target.classList.contains('calendar-body')) {
        if (target.classList.contains('clickable')) {
          return
        }
        target = target.parentElement
      }

      const { user, operator, leaveCalendar } = this.props
      const leaveSlotsTarget = user || operator || leaveCalendar
      const leaveSlots = leaveSlotsTarget && leaveSlotsTarget.leaveSlots

      if (leaveSlots) {
        e.preventDefault()
        const date = this.getDateFromMouseEvent(e)
        this.drag = { start: date, end: date }
      }
    }
  }

  onCalendarMouseMove(e) {
    if (this.drag) {
      const date = this.getDateFromMouseEvent(e)
      this.drag = { ...this.drag, end: date }
    }
  }

  onCalendarMouseUp(e) {
    if (e.button === 0 && this.drag) {
      const { user, operator, leaveCalendar } = this.props
      const employee = user || operator
      const target = employee || leaveCalendar

      if (target.isNew) {
        showNotification({
          message: t(`${snakeToCamel(target.constructor.backendResourceName)}.edit.saveBeforeLeaveSlot`),
          dismissAfter: 5000,
          error: true,
        })
        return
      }

      const currentUser =
        (user && window.viewStore.currentUser.id === user.id) ||
        (operator && window.viewStore.currentOperator.id === operator.id)

      const status = currentUser && !employee.manager.isNew ? 'pending' : 'approved'

      this.leaveSlot = new LeaveSlot(
        {
          status,
          type: employee ? 'vacation' : null,
          startDate: this.absoluteDrag.start.clone().startOf('day'),
          endDate: this.absoluteDrag.end.clone().endOf('day'),
        },
        {
          relations: target.leaveSlots.__activeRelations,
        }
      )
    }
  }

  onMouseUp(e) {
    if (e.button === 0) {
      this.drag = null
    }
  }

  async onLeaveSlotSubmit() {
    const { user, operator, leaveCalendar } = this.props

    const target = user || operator || leaveCalendar
    const leaveSlots = target.leaveSlots
    const currentUser =
      (user && window.viewStore.currentUser.id === user.id) ||
      (operator && window.viewStore.currentOperator.id === operator.id)

    const originalId = this.leaveSlot.id
    await this.leaveSlot.save({
      onlyChanges: true,
      data: {
        user: user ? user.id : null,
        operator: operator ? operator.id : null,
        leave_calendar: leaveCalendar ? leaveCalendar.id : null,
        ...(currentUser && !target.manager.isNew ? { status: 'pending' } : {}),
      },
    })
    showSaveNotification()

    runInAction(() => {
      if (originalId) {
        leaveSlots.get(originalId).parse(this.leaveSlot.toJS())
      } else {
        leaveSlots.models.push(new LeaveSlot(this.leaveSlot.toJS(), { relations: leaveSlots.__activeRelations }))
      }
      this.onLeaveSlotCancel()
    })
  }

  onLeaveSlotCancel() {
    this.leaveSlot = null
  }

  render() {
    const { user, operator, leaveCalendar } = this.props
    let formLabel = null
    if (user) {
      formLabel = t('user.field.availability.label')
    } else if (operator) {
      formLabel = t('operator.field.availability.label')
    } else if (leaveCalendar) {
      formLabel = t('leaveCalendar.field.leaveSlots.label')
    }

    return (
      <Form.Field>
        <label>
          {formLabel}
        </label>
        <CalendarContainer>
          <CalendarHeader data-test-calendar-header>
            {this.scope.type === 'week'
              ? t('daycy.week.value', this.scope)
              : `${t(`daycy.month.${MONTHS[this.scope.month]}`)} ${this.scope.year}`}
            <CalendarHeaderButton name="chevron left" side="left" onClick={this.setPrevScope} />
            <CalendarHeaderButton name="chevron right" side="right" onClick={this.setNextScope} />
            <CalendarDays>{dateRange(this.scopeStart, this.scopeEnd).map(this.renderDay)}</CalendarDays>
          </CalendarHeader>
          <CalendarBody
            className="calendar-body"
            data-test-calendar-body={true}
            onMouseDown={this.onCalendarMouseDown}
            onMouseMove={this.onCalendarMouseMove}
            onMouseUp={this.onCalendarMouseUp}
          >
            {this.absoluteDrag && (
              <CalendarDrag
                days={this.days}
                day={this.absoluteDrag.start.diff(this.scopeStart, 'd')}
                width={this.absoluteDrag.end.diff(this.absoluteDrag.start, 'd') + 1}
              />
            )}
            {this.scopedWorkedSlots.map(this.renderWorkSlot)}
            {this.scopedLeaveCalendars.map(this.renderLeaveCalendar)}
            {this.scopedLeaveSlots.map(this.renderLeaveSlot)}
          </CalendarBody>
        </CalendarContainer>
        <ScopeContainer>
          {this.workTimes !== null && (
            <>
              <TotalTime data-test-total-planned>
                {t('workCalendar.total.planned', {
                  time: formatDuration(this.totalTimePlanned, { unit: 'minute', maxUnit: 'hour' }),
                })}
              </TotalTime>
              <TotalTime data-test-total-actual>
                {t('workCalendar.total.actual', {
                  time: formatDuration(this.totalTimeActual, { unit: 'minute', maxUnit: 'hour' }),
                })}
              </TotalTime>
              {this.totalTimeActual > this.totalTimePlanned ? (
                <TotalOvertime data-test-total-overtime>
                  {t('workCalendar.total.overtime', {
                    time: formatDuration(this.totalTimeActual - this.totalTimePlanned, {
                      unit: 'minute',
                      maxUnit: 'hour',
                    }),
                  })}
                </TotalOvertime>
              ) : this.totalTimePlanned > this.totalTimeActual ? (
                <TotalAbsence data-test-total-absence>
                  {t('workCalendar.total.absence', {
                    time: formatDuration(this.totalTimePlanned - this.totalTimeActual, {
                      unit: 'minute',
                      maxUnit: 'hour',
                    }),
                  })}
                </TotalAbsence>
              ) : null}
              <ScrollModal
                closeIcon
                trigger={<WorkTimesButton data-test-edit-work-times name="pen" />}
                onClose={() =>
                  (this.workTimes.store.models = this.workTimes.store.models.filter((workTime) => !workTime.isNew))
                }
              >
                <ScrollModal.Header>
                  {t('workCalendar.workTimesModal.title', {
                    scope:
                      this.scope.type === 'week'
                        ? t('daycy.week.value', this.scope)
                        : `${t(`daycy.month.${MONTHS[this.scope.month]}`)} ${this.scope.year}`,
                  })}
                </ScrollModal.Header>
                <ScrollModal.Content>
                  <Form>
                    <Table>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell content={t('workTime.field.date.label')} />
                          <Table.HeaderCell content={t('workTime.field.startTime.label')} />
                          <Table.HeaderCell content={t('workTime.field.endTime.label')} />
                          <Table.HeaderCell content={t('workTime.field.automaticallyClockedOut.label')} />
                          <Table.HeaderCell collapsing />
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {this.workTimes.store.map((workTime) => (
                          <Table.Row key={workTime.cid}>
                            <Table.Cell
                              content={
                                workTime.isNew ? (
                                  <TargetDatePicker
                                    noLabel
                                    target={workTime}
                                    name="startTime"
                                    onChange={action((date) => {
                                      const startTime = workTime.startTime.clone().set({
                                        year: date.year(),
                                        month: date.month(),
                                        date: date.date(),
                                      })
                                      const endTime = workTime.endTime.clone().set({
                                        year: date.year(),
                                        month: date.month(),
                                        date: date.date(),
                                      })
                                      if (endTime.isBefore(startTime)) {
                                        endTime.add(1, 'd')
                                      }
                                      workTime.setInput('startTime', startTime)
                                      workTime.setInput('endTime', endTime)
                                    })}
                                  />
                                ) : (
                                  workTime.startTime.format(DATE_FORMAT)
                                )
                              }
                            />
                            <Table.Cell
                              content={
                                workTime.isNew ? (
                                  <TargetTimePicker
                                    noLabel
                                    target={workTime}
                                    name="startTime"
                                    onChange={action((time) => {
                                      const startTime = workTime.startTime.clone().set({
                                        hour: time.hour(),
                                        minute: time.minute(),
                                        second: time.second(),
                                        millisecond: time.millisecond(),
                                      })
                                      const endTime = workTime.endTime.clone().set({
                                        year: startTime.year(),
                                        month: startTime.month(),
                                        date: startTime.date(),
                                      })
                                      if (endTime.isBefore(startTime)) {
                                        endTime.add(1, 'd')
                                      }
                                      workTime.setInput('startTime', startTime)
                                      workTime.setInput('endTime', endTime)
                                    })}
                                  />
                                ) : (
                                  workTime.startTime.format(TIME_FORMAT)
                                )
                              }
                            />
                            <Table.Cell
                              content={
                                workTime.isNew ? (
                                  <TargetTimePicker
                                    noLabel
                                    target={workTime}
                                    name="endTime"
                                    onChange={action((time) => {
                                      const endTime = workTime.endTime.clone().set({
                                        year: workTime.startTime.year(),
                                        month: workTime.startTime.month(),
                                        date: workTime.startTime.date(),
                                        hour: time.hour(),
                                        minute: time.minute(),
                                        second: time.second(),
                                        millisecond: time.millisecond(),
                                      })
                                      if (endTime.isBefore(workTime.startTime)) {
                                        endTime.add(1, 'd')
                                      }
                                      workTime.setInput('endTime', endTime)
                                    })}
                                    errors={
                                      workTime.backendValidationErrors && workTime.backendValidationErrors.__all__
                                    }
                                  />
                                ) : workTime.endTime ? (
                                  workTime.endTime.format(TIME_FORMAT)
                                ) : (
                                  '-'
                                )
                              }
                            />
                            <Table.Cell content={workTime.automaticallyClockedOut && <Icon name="check" />} />
                            <Table.Cell
                              content={
                                workTime.isNew ? (
                                  <Button
                                    primary
                                    data-test-save-work-time-button
                                    icon="save"
                                    onClick={() =>
                                      workTime
                                        .save({
                                          data: {
                                            [(user || operator).constructor.backendResourceName]: (user || operator).id,
                                          },
                                        })
                                        .then(() => showSaveNotification())
                                    }
                                    loading={workTime.isLoading}
                                  />
                                ) : (
                                  <Button
                                    data-test-delete-work-time-button
                                    icon="delete"
                                    onClick={() => workTime.delete().then(() => showSaveNotification())}
                                    loading={workTime.isLoading}
                                  />
                                )
                              }
                            />
                          </Table.Row>
                        ))}
                      </Table.Body>
                    </Table>
                    <div style={{ textAlign: 'center' }}>
                      <Button
                        primary
                        data-test-add-work-time-button
                        icon="add"
                        content={t('form.addButton')}
                        onClick={() =>
                          this.workTimes.store.add({
                            startTime: this.scopeStart.format(SERVER_DATETIME_FORMAT),
                            endTime: this.scopeStart.format(SERVER_DATETIME_FORMAT),
                          })
                        }
                      />
                    </div>
                  </Form>
                </ScrollModal.Content>
              </ScrollModal>
            </>
          )}
          <RightDivider />
          <Dropdown inline value={this.scope.type} onChange={this.onScopeChange} options={SCOPE_OPTIONS} />
        </ScopeContainer>
        {this.leaveSlot && (
          <LeaveSlotModal open closeIcon data-test-leave-slot-modal size="small" onClose={this.onLeaveSlotCancel}>
            <ScrollModal.Header>
              {t(`workCalendar.leaveSlotModal.title.${this.leaveSlot.isNew ? 'add' : 'edit'}`)}
            </ScrollModal.Header>
            <LeaveSlotModalContent>
              <LeaveSlotModalInfo>
                <Form>
                  <LeaveSlotWindow leaveSlot={this.leaveSlot} />
                  {this.leaveSlot.type !== null && (
                    <TargetRadioButtons
                      target={this.leaveSlot}
                      name="type"
                      options={LeaveSlot.TYPES.map((type) => ({
                        value: type,
                        text: t(`leaveSlot.field.type.value.${type}`),
                      }))}
                    />
                  )}
                  <TargetTextInput
                    autoFocus
                    target={this.leaveSlot}
                    name="name"
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault()
                        this.onLeaveSlotSubmit()
                      }
                    }}
                  />
                </Form>
              </LeaveSlotModalInfo>
              {!leaveCalendar && !this.leaveSlot.isNew && (
                <LeaveSlotViewComments user={user} operator={operator} leaveSlot={this.leaveSlot} />
              )}
            </LeaveSlotModalContent>
            <ScrollModal.Actions>
              <RightDivider />
              <Button
                primary
                {...(this.leaveSlot.isNew ? { 'data-test-add-button': true } : { 'data-test-save-button': true })}
                icon={this.leaveSlot.isNew ? 'add' : 'save'}
                labelPosition="left"
                content={t(`form.${this.leaveSlot.isNew ? 'add' : 'save'}Button`)}
                onClick={this.onLeaveSlotSubmit}
              />
            </ScrollModal.Actions>
          </LeaveSlotModal>
        )}
      </Form.Field>
    )
  }
}
